/*
Authors: Joel Wilkinson
Details: Unit.js is the component that renders a Unit with
an inner component (People and other relational info).

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";

import { getPeopleList, resetPeopleList, resetReadingsList } from "../../Actions/functions";

import "./Unit.css";

const Unit = (props) => {

    const loadUnitInfo = () => {
        props.resetPeopleList();
        props.resetReadingsList();
        props.getPeopleList(props.props.unitId);
        props.props.setUnitId(props.props.unitId);
        props.props.clicked(true);
    }

    if (props.unitList !== undefined || props.unitList !== null || props.unitList === []) {
        return (
            <div className="unit-widget" onClick={() => loadUnitInfo()}>
                <div>
                    <FontAwesomeIcon icon={faDoorClosed} color="black" />
                </div>
                <p>{props.props.unitNumber} {props.props.archived === 1 ? "(Archived)" : ""}</p>
            </div>
        );
    } else {
        return (
            <div>Loading Unit...</div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        unitList: state.unitGetReducer.unitList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getPeopleList: getPeopleList,
            resetPeopleList: resetPeopleList,
            resetReadingsList: resetReadingsList
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Unit);
