import { GET_LINE_ITEMS_ACTION } from "../Actions/types";

const lineItemsGetState = {
    lineItemsList: [],
};

const lineItemsGetReducer = (state = lineItemsGetState, action) => {
    switch (action.type) {
        case GET_LINE_ITEMS_ACTION:
            state = {
                ...state,
                lineItemsList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default lineItemsGetReducer;
