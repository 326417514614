/*
Authors: Joel Wilkinson
Details: FormAddBuilding.js is the component that renders the add
building form.

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { addBuilding, getBuildingList } from "../../Actions/functions";

import "./Building.css";

const FormAddBuilding = (props) => {

    const [name, setName] = useState("");
    const [street, setStreet] = useState("");
    const [suburb, setSuburb] = useState("");
    const [postCode, setPostCode] = useState("");
    const [state, setState] = useState("QLD");
    const [nmi, setNmi] = useState("");

    const handleSubmitAddBuilding = (e) => {
        e.preventDefault();

        if (name === "" || street === "" || suburb === "" || postCode === "" || state === "" || nmi === "") {
            const fields = [name, street, suburb, postCode, state, nmi];
            const fieldNames = [
                "Building Name", "Street", "Suburb", "Post Code", "State", "NMI"
            ];

            let message = "You have missing fields in:";

            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === "") {
                    message += "\n" + fieldNames[i];
                }
            }

            alert(message);
        } else {
            props.addBuilding(name, street, suburb, postCode, state, nmi);
            setName("");
            setStreet("");
            setSuburb("");
            setPostCode("");
            setState("");
            setNmi("");
            setTimeout(() => {
                props.getBuildingList();
            }, 1000);
            props.props.reset(false);
        }
    }

    return (
        <div>
            <div>
                <button className="form-btn" onClick={() => props.props.reset(false)}>Close</button>
            </div>
            <Form name="formAddBuilding" className="form-add-building" onSubmit={handleSubmitAddBuilding}>
                <Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">Building Name</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            type="name"
                            placeholder="Building Name..."
                            name="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row><Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">Street</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            type="street"
                            placeholder="Street..."
                            name="street"
                            value={street}
                            onChange={e => setStreet(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">Suburb</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            type="suburb"
                            placeholder="Suburb..."
                            name="suburb"
                            value={suburb}
                            onChange={e => setSuburb(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">Post Code</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            type="number"
                            placeholder="Post Code..."
                            name="postCode"
                            value={postCode}
                            onChange={e => setPostCode(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">State</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            as="select"
                            type="state"
                            placeholder="State..."
                            name="state"
                            value={state}
                            onChange={e => setState(e.target.value)}
                        >
                            <option>QLD</option>
                            <option>NSW</option>
                            <option>VIC</option>
                            <option>NT</option>
                            <option>WA</option>
                            <option>SA</option>
                            <option>ACT</option>
                            <option>TAS</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="building-form-group">
                        <Form.Label className="building-label-field">NMI</Form.Label>
                        <Form.Control
                            className="building-form-control"
                            type="nmi"
                            placeholder="NMI..."
                            name="nmi"
                            value={nmi}
                            onChange={e => setNmi(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Group as={Col}>
                    <button type="submit" className="form-btn">Add Building</button>
                </Form.Group>
            </Form>
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        waiting: state.waitingStateReducer.waiting,
        buildingAdded: state.buildingAddReducer.buildingAdded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addBuilding: addBuilding,
            getBuildingList: getBuildingList
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddBuilding);
