/*
Authors: Joel Wilkinson
Details: SectionBuilding.js is the component for managing the section where
everything building/unit/person etc related lives.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { verifyAuthentication, userTimeout, getBuildingList, getDiscountRates } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";

import "./Dashboard.css";
import Building from "../Building/Building";
import Unit from "../Unit/Unit";
import UnitManagement from "../UnitManagement/UnitManagement";
import FormAddBuilding from "../Building/FormAddBuilding";
import FormUpdateBuilding from "../Building/FormUpdateBuilding";
import FormAddUnit from "../Unit/FormAddUnit";
import FormUpdateUnit from "../Unit/FormUpdateUnit";

const SectionBuilding = (props) => {
  const [viewingUnits, setViewingUnits] = useState(false);
  const [viewingUnitManagement, setViewingUnitManagement] = useState(false);
  const [addingBuilding, setAddingBuilding] = useState(false);
  const [updatingBuilding, setUpdatingBuilding] = useState(false);
  const [addingUnit, setAddingUnit] = useState(false);
  const [updatingUnit, setUpdatingUnit] = useState(false);
  const [switchUnitTextRender, setSwitchUnitTextRender] = useState(false);
  const [buildingId, setBuildingId] = useState(1);
  const [unitId, setUnitId] = useState(1);
  const [loadedBuildings, setLoadedBuildings] = useState(false);

  const [data, setData] = useState(false);

  const { getBuildingList, getDiscountRates } = props;

  useEffect(() => {
    if (!data) {
      getBuildingList();
      getDiscountRates();
      setData(true);
    }
  }, [data, getBuildingList, getDiscountRates]);

  const switchToUnits = (val, id) => {
    setViewingUnits(val);
    setBuildingId(id);
    setSwitchUnitTextRender(false);
  }

  const retrieveBuildingList = () => {
    if (props.buildingList === undefined || props.buildingList === null) {
      return (
        <div>
          Loading Buildings...
        </div>
      )
    } else {
      return props.buildingList.map((k, i) => {
        if (i === props.buildingList.length - 1 && !loadedBuildings) {
          setLoadedBuildings(true);
        }
        return <Building key={k.idbuilding}
          props={{ id: k.idbuilding, clicked: switchToUnits, buildingName: k.name, street: k.street, suburb: k.suburb, postCode: k.postCode, state: k.state, nmi: k.nmi }}
        />
      })
    }
  }

  const getUnitList = () => {
    if (props.unitList !== undefined || props.unitList !== null) {
      return props.unitList.map((k, i) => {
        return <Unit
          key={k.idunit}
          props={{
            buildingId: buildingId,
            unitNumber: k.unitNum,
            unitId: k.idunit,
            archived: k.archived,
            clickedU: setViewingUnits,
            clicked: setViewingUnitManagement,
            setUnitId: setUnitId
          }}
        />
      })
    }
  }

  const renderLoadingUnits = () => {
    setTimeout(() => {
      setSwitchUnitTextRender(true);
    }, 1500);
    return (
      <div style={{ margin: "0 auto", textAlign: "center", color: "black" }}>Loading Units...</div>
    )
  }

  return (
    <div className="section-building">
      {/* <div className="dashboard-building-list"> */}
      {/* viewingUnits && viewingUnitManagement both false = render buildings */}
      {/* viewingUnits is true && viewingUnitManagement false = render units */}
      {/* Otherwise, render people! */}
      {!viewingUnits && !viewingUnitManagement ? (
        <div style={{ width: "100%" }}>
          {!addingBuilding && !updatingBuilding ?
            <div>
              <button className="add-update-btn" onClick={() => setAddingBuilding(true)}>Add Building</button>
              <button className="add-update-btn" onClick={() => setUpdatingBuilding(true)}>Update Building</button>
            </div>
            : addingBuilding && !updatingBuilding ?
              <FormAddBuilding props={{ reset: setAddingBuilding }} /> : <FormUpdateBuilding props={{ reset: setUpdatingBuilding }} />}
          <div className="dashboard-building-list">
            {!loadedBuildings ? <div style={{ textAlign: "center", margin: "0 auto" }}>Loading Buildings...</div> : ""}
            {retrieveBuildingList()}
          </div>
        </div>
      ) : viewingUnits && !viewingUnitManagement ? (
        <div style={{ width: "100%" }}>
          <button
            className="btn btn-return"
            onClick={() => setViewingUnits(false)}
          >
            Return To Buildings
          </button>
          {!addingUnit && !updatingUnit ?
            <div>
              <button className="add-update-btn" onClick={() => setAddingUnit(true)}>Add Unit</button>
              <button className="add-update-btn" onClick={() => setUpdatingUnit(true)}>Update Unit</button>
            </div> :
            addingUnit && !updatingUnit ?
              <FormAddUnit props={{ buildingId: buildingId, reset: setAddingUnit }} /> : <FormUpdateUnit props={{ reset: setUpdatingUnit, buildingId: buildingId }} />
          }
          <div className="dashboard-building-list">
            {props.unitList.length === 0 && !switchUnitTextRender ? renderLoadingUnits() : ""}
            <div className="dashboard-unit-list">
              {getUnitList()}
            </div>
          </div>
        </div>
      ) : (
        <UnitManagement
          props={{
            clicked: setViewingUnitManagement,
            unitId: unitId,
          }}
        />
      )}
      {/* </div> */}
    </div>
  );


}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    name: state.loginReducer.name,
    accountType: state.loginReducer.accountType,
    buildingList: state.buildingGetReducer.buildingList,
    unitList: state.unitGetReducer.unitList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      getBuildingList: getBuildingList,
      getDiscountRates: getDiscountRates,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionBuilding);
