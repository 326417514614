/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
//import { connectRouter } from "connected-react-router";
//import { createBrowserHistory } from "history";
import loginReducer from "./Reducers/login";
import checkAuthReducer from "./Reducers/authentication";
import changePasswordReducer from "./Reducers/password";
import getTFAReducer from "./Reducers/tfaget";
import postTFAReducer from "./Reducers/tfapost";
import documentHeightReducer from "./Reducers/documentheight";
import waitingStateReducer from "./Reducers/waiting";
import buildingAddReducer from "./Reducers/buildingadd";
import buildingGetReducer from "./Reducers/buildingget";
import unitGetReducer from "./Reducers/unitget";
import discountRatesGetReducer from "./Reducers/discountratesget";
import unitAddReducer from "./Reducers/unitadd";
import unitUpdateReducer from "./Reducers/unitupdate";
import peopleGetReducer from "./Reducers/peopleget";
import peopleAddReducer from "./Reducers/peopleadd";
import peopleUpdateReducer from "./Reducers/peopleupdate";
import readingsGetReducer from "./Reducers/readingsget";
import readingsAddReducer from "./Reducers/readingsadd";
import readingsUpdateReducer from "./Reducers/readingsupdate";
import discountRatesAddReducer from "./Reducers/discountratesadd";
import discountRatesUpdateReducer from "./Reducers/discountratesupdate";
import lineItemsGetReducer from "./Reducers/lineitemsget";
import lineItemsAddReducer from "./Reducers/lineitemsadd";
import lineItemsUpdateReducer from "./Reducers/lineitemsupdate";
import activeLineItemsGetReducer from "./Reducers/activelineitemsget";
import nonActiveLineItemsGetReducer from "./Reducers/nonactivelineitemsget";
import activeLineItemsAddReducer from "./Reducers/activelineitemsadd";
import activeLineItemsDeleteReducer from "./Reducers/activelineitemsdelete";
import invoicesTestGenerateGetReducer from "./Reducers/invoicestestgenerate";
import invoicesPreviousGetReducer from "./Reducers/invoicespreviousget";
import invoicesUnpaidGetReducer from "./Reducers/invoicesunpaidget";
import missingUnitsGetReducer from "./Reducers/unitnoreading";
import readyUnitsGetReducer from "./Reducers/unitinvoicereadyreadingget";
import invoicesUnpaidUpdateReducer from "./Reducers/invoicesunpaidupdate";
import getStatementReducer from "./Reducers/statement";
//const history = createBrowserHistory();
import storage from "redux-persist/lib/storage";
import { RESET_STATE_ACTION } from "./Actions/types";

const initState = {
  // username: "",
  // name: "",
  // secret: "",
  // authenticated: false,
  // sessionExists: false,
  // tempSecret: "",
  // qrCode: "",
  // tfaConfimed: "",
  // height: "100vh",
  // waiting: false,
  // accountType: 0,
  // buildingList: [],
  // buildingAdded: "",
  // unitList: [],
  // discountRatesList: [],
  // unitAdded: "",
  // unitUpdated: "",
  // discountRatesObj: {},
  // peopleList: [],
  // peopleAdded: "",
  // peopleUpdated: "",
  // readingList: "",
  // readingAdded: "",
  // readingUpdated: "",
  // discountRatesAdded: "",
  // lineItemsList: [],
  // lineItemAdded: "",
  // lineItemUpdated: "",
};

const rootReducer = (state = initState, action) => {
  if (action.type === RESET_STATE_ACTION) {
    //console.log("Resetting state...");
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }
  return appReducer(state, action);
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

const appReducer = combineReducers({
  loginReducer,
  checkAuthReducer,
  changePasswordReducer,
  getTFAReducer,
  postTFAReducer,
  documentHeightReducer,
  waitingStateReducer,
  buildingAddReducer,
  buildingGetReducer,
  unitGetReducer,
  discountRatesGetReducer,
  unitAddReducer,
  unitUpdateReducer,
  peopleGetReducer,
  peopleAddReducer,
  peopleUpdateReducer,
  readingsGetReducer,
  readingsAddReducer,
  readingsUpdateReducer,
  discountRatesAddReducer,
  discountRatesUpdateReducer,
  lineItemsGetReducer,
  lineItemsAddReducer,
  lineItemsUpdateReducer,
  activeLineItemsGetReducer,
  nonActiveLineItemsGetReducer,
  activeLineItemsAddReducer,
  activeLineItemsDeleteReducer,
  invoicesTestGenerateGetReducer,
  invoicesPreviousGetReducer,
  invoicesUnpaidGetReducer,
  missingUnitsGetReducer,
  readyUnitsGetReducer,
  invoicesUnpaidUpdateReducer,
  getStatementReducer
});

export default rootReducer;
