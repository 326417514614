export const POST_LOGIN_ACTION = "POST_LOGIN_ACTION";

export const FAILED_LOGIN_ACTION = "FAILED_LOGIN_ACTION";

export const CHECK_LOGGEDIN_ACTION = "CHECK_LOGGEDIN_ACTION";

export const USER_TIMEOUT_ACTION = "USER_TIMEOUT_ACTION";

export const UPDATE_PASSWORD_ACTION = "UPDATE_PASSWORD_ACTION";

export const GET_TFA_ACTION = "GET_TFA_ACTION";

export const POST_TFA_ACTION = "POST_TFA_ACTION";

export const POST_TFALOGIN_ACTION = "POST_TFALOGIN_ACTION";

export const RESET_STATE_ACTION = "RESET_STATE_ACTION";

export const GET_DOCUMENT_HEIGHT_ACTION = "GET_DOCUMENT_HEIGHT_ACTION";

export const WAITING_SERVER_RESPONSE_ACTION = "WAITING_SERVER_RESPONSE_ACTION";

export const GET_BUILDING_ACTION = "GET_BUILDING_ACTION";

export const ADD_BUILDING_ACTION = "ADD_BUILDING_ACTION";

export const UPDATE_BUILDING_ACTION = "UPDATE_BUILDING_ACTION";

export const GET_UNIT_ACTION = "GET_UNIT_ACTION";

export const RESET_UNIT_ACTION = "RESET_UNIT_ACTION";

export const GET_DISCOUNT_RATES_ACTION = "GET_DISCOUNT_RATES_ACTION";

export const ADD_UNIT_ACTION = "ADD_UNIT_ACTION";

export const UPDATE_UNIT_ACTION = "UPDATE_UNIT_ACTION";

export const GET_PEOPLE_ACTION = "GET_PEOPLE_ACTION";

export const RESET_PEOPLE_ACTION = "RESET_PEOPLE_ACTION";

export const ADD_PEOPLE_ACTION = "ADD_PEOPLE_ACTION";

export const UPDATE_PEOPLE_ACTION = "UPDATE_PEOPLE_ACTION";

export const GET_READINGS_ACTION = "GET_READINGS_ACTION";

export const RESET_READINGS_ACTION = "RESET_READINGS_ACTION";

export const ADD_READINGS_ACTION = "ADD_READINGS_ACTION";

export const UPDATE_READINGS_ACTION = "UPDATE_READINGS_ACTION";

export const ADD_DISCOUNT_RATES_ACTION = "ADD_DISCOUNT_RATES_ACTION";

export const UPDATE_DISCOUNT_RATES_ACTION = "UPDATE_DISCOUNT_RATES_ACTION";

export const GET_LINE_ITEMS_ACTION = "GET_LINE_ITEMS_ACTION";

export const ADD_LINE_ITEMS_ACTION = "ADD_LINE_ITEMS_ACTION";

export const UPDATE_LINE_ITEMS_ACTION = "UPDATE_LINE_ITEMS_ACTION";

export const GET_ACTIVE_LINE_ITEMS_ACTION = "GET_ACTIVE_LINE_ITEMS_ACTION";

export const GET_NON_ACTIVE_LINE_ITEMS_ACTION = "GET_NON_ACTIVE_LINE_ITEMS_ACTION";

export const ADD_ACTIVE_LINE_ITEMS_ACTION = "ADD_ACTIVE_LINE_ITEMS_ACTION";

export const DELETE_ACTIVE_LINE_ITEMS_ACTION = "DELETE_ACTIVE_LINE_ITEMS_ACTION";

export const GENERATE_TEST_INVOICES_ACTION = "GENERATE_TEST_INVOICES_ACTION";

export const GET_PREVIOUS_INVOICES_ACTION = "GET_PREVIOUS_INVOICES_ACTION";

export const GET_UNPAID_INVOICES_ACTION = "GET_UNPAID_INVOICES_ACTION";

export const GET_MISSING_UNIT_READINGS_ACTION = "GET_MISSING_UNIT_READINGS_ACTION";

export const GET_UNIT_INVOICE_READY_READINGS_ACTION = "GET_UNIT_INVOICE_READY_READINGS_ACTION";

export const UPDATE_UNPAID_INVOICES_ACTION = "UPDATE_UNPAID_INVOICES_ACTION";

export const GET_UNPAID_ALL_INVOICES_ACTION = "GET_UNPAID_ALL_INVOICES_ACTION";

export const GET_STATEMENT_ALL_ACTION = "GET_STATEMENT_ALL_ACTION";

export const GET_STATEMENT_UNIT_ACTION = "GET_STATEMENT_UNIT_ACTION";