import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import "./Transactions.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LatePaymentSelector = (props) => {
  const latePaymentData = props.latePayments;
  const classes = useStyles();
  const [selectedPenalty, setSelectedPenalty] = useState({});

  const handleChange = (event) => {
    setSelectedPenalty(event.target.value);
  };

  return (
    <div>
      {" "}
      <>
        {latePaymentData.length > 0 ? (
          <>
            <h4 className="latePaymentSelector-h4">
              There are currently overdue invoices
            </h4>
            <FormControl className={classes.formControl}>
              <InputLabel>Current Overdue Invoices</InputLabel>
              <Select
                value={selectedPenalty.description}
                onChange={handleChange}
              >
                {latePaymentData.map((data) => (
                  <MenuItem key={data.idinvoice} value={data}>
                    {`Invoice ${data.invoiceNumber} Overdue.\nApply late payment variance of $${data.amount}`}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>
                Choose the invoice for which a late payment variance is required
                to be applied.
              </FormHelperText>
            </FormControl>
          </>
        ) : (
          ""
        )}
      </>
      {selectedPenalty.idinvoice ? (
        <Button
          style={{ minHeight: "50px", marginTop: "22px" }}
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.postTransaction(selectedPenalty)}
        >
          Apply Charge
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default LatePaymentSelector;
