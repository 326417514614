import { GET_NON_ACTIVE_LINE_ITEMS_ACTION } from "../Actions/types";

const nonActiveLineItemsGetState = {
    nonActiveLineItemsList: [],
};

const nonActiveLineItemsGetReducer = (state = nonActiveLineItemsGetState, action) => {
    switch (action.type) {
        case GET_NON_ACTIVE_LINE_ITEMS_ACTION:
            state = {
                ...state,
                nonActiveLineItemsList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default nonActiveLineItemsGetReducer;
