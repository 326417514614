import { UPDATE_UNIT_ACTION } from "../Actions/types";

const unitUpdateState = {
    unitUpdated: "",
};

const unitUpdateReducer = (state = unitUpdateState, action) => {
    switch (action.type) {
        case UPDATE_UNIT_ACTION:
            state = {
                ...state,
                unitUpdated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default unitUpdateReducer;
