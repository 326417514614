import { GET_STATEMENT_ALL_ACTION, GET_STATEMENT_UNIT_ACTION } from "../Actions/types";

const getStatementState = {
    statementData: [],
};

const getStatementReducer = (state = getStatementState, action) => {
    switch (action.type) {
        case GET_STATEMENT_ALL_ACTION:
            state = {
                ...state,
                statementData: action.payload,
            };
            return state;
        case GET_STATEMENT_UNIT_ACTION:
            state = {
                ...state,
                statementData: action.payload,
            };
            return state;
        default:
            return state;
    }
};
export default getStatementReducer;
