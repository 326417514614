import { UPDATE_UNPAID_INVOICES_ACTION } from "../Actions/types";

const invoicesUnpaidUpdateState = {
    updated: [],
};

const invoicesUnpaidUpdateReducer = (state = invoicesUnpaidUpdateState, action) => {
    switch (action.type) {
        case UPDATE_UNPAID_INVOICES_ACTION:
            state = {
                ...state,
                updated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default invoicesUnpaidUpdateReducer;
