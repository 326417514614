/*
Authors: Joel Wilkinson
Details: SideBar.js is the component for rendering the sidebar
on the side of the screen when the user is viewing OMS on
a desktop.

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

// import Password from "./Password";
// import TwoFactorAuth from "./TwoFactorAuth";

import { documentHeight } from "../../Actions/functions";
import "./SideBar.css";

// class SideBar extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       height: 0,
//     };
//   }

//   componentDidUpdate() {
//     if (this.props.height > 0) {
//       this.setState({ height: this.props.height });
//       this.props.documentHeight(0);
//     }
//   }

const SideBar = (props) => {

  const [height,] = useState(0);

  return (
    <div className="sideBarBody" style={{ minHeight: 200 }}>
      <div className="stickySideBar" style={{ minHeight: height }}>
        <Button
          fullWidth="true"
        >
          <div className="icon-title" onClick={() => props.props.home()}><FontAwesomeIcon
            icon={faHome}
            color="#575652"
            size="2x"
          /><br></br>Home</div>
        </Button>
        <Button
          fullWidth="true"
        >
          <div className="icon-title" onClick={() => props.props.building()}><FontAwesomeIcon
            icon={faBuilding}
            color="#575652"
            size="2x"
          /><br></br>Buildings</div>
        </Button>
        <Button
          fullWidth="true"
        >
          <div className="icon-title" onClick={() => props.props.invoices()}><FontAwesomeIcon
            icon={faFileInvoice}
            color="#575652"
            size="2x"
          /><br></br>Invoices</div>
        </Button>
        <Button
          fullWidth="true"
        >
          <div className="icon-title" onClick={() => props.props.discountRates()}><FontAwesomeIcon
            icon={faCalculator}
            color="#575652"
            size="2x"
          /><br></br>Rates</div>
        </Button>
        <Button
          fullWidth="true"
        >
          <div className="icon-title" onClick={() => props.props.lineItems()}><FontAwesomeIcon
            icon={faClipboardList}
            color="#575652"
            size="2x"
          /><br></br>Line Items</div>
        </Button>
        <div className="userSettings">
          {/* <Button className="sideBtn" fullWidth="true">
                Account Settings
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="black"
                  className="iconEnd"
                />
              </Button>
              <Password></Password>
              {this.props.secret !== true ? (
                <TwoFactorAuth></TwoFactorAuth>
              ) : (
                ""
              )} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.loginReducer.name,
    secret: state.loginReducer.secret,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      documentHeight: documentHeight
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
