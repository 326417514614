/*
Authors: Joel Wilkinson
Details: SectionHome.js is the first rendered section
to the user.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { endOfMonth, format } from "date-fns";
import { CSVLink } from "react-csv";
import Transactions from "../Transactions/Transactions"

import { generateTestInvoices, retrieveMissingUnitReadings, retrieveReadyInvoiceUnitReadings, getBuildingList, retrieveStatementAll } from "../../Actions/functions";

import "./Dashboard.css";

const SectionHome = (props) => {
    const [data,] = useState(false);
    const [dueDate, setDueDate] = useState(format(new Date(endOfMonth(new Date())), "yyyy-MM-dd"));
    const [isSelected, setIsSelected] = useState(false);

    const { retrieveMissingUnitReadings, retrieveReadyInvoiceUnitReadings } = props;

    useEffect(() => {
        props.getBuildingList();
        props.retrieveStatementAll();
    }, [])

    useEffect(() => {
        if (!data) {
            retrieveMissingUnitReadings();
            retrieveReadyInvoiceUnitReadings();
        }

    }, [data, retrieveMissingUnitReadings, retrieveReadyInvoiceUnitReadings]);

    const renderMissingUnits = () => {
        return props.missingUnits.map((k, i) => {
            return <div key={i} className="home-missing-readings-item">{k.name} - {k.unitNum}</div>
        })
    }

    const renderReadyUnits = () => {
        return props.readyUnits.map((k, i) => {
            return <div key={i} className="home-missing-readings-item">{k.name} - {k.unitNum}</div>
        })
    }

    const generateInvoices = (buildingId, buildingName) => {
        props.generateTestInvoices(buildingId, dueDate, buildingName);
        setIsSelected(true);

        setTimeout(() => {
            props.getBuildingList();
            retrieveReadyInvoiceUnitReadings();
        }, 2500);
    }

    const renderGenerateInvoicesCheck = () => {
        if (props.buildingList !== undefined) {
            return props.buildingList.map((k, i) => {
                if ((k.invoicesReady > 0) && (!isSelected)) {
                    return <div key={k.idbuilding} className="home-generate-invoices-download" onClick={() => generateInvoices(k.idbuilding, k.name)}><p>Generate Downloadable Invoices</p><p style={{ fontSize: "0.7em" }}>({k.name})</p></div>
                } else {
                    return <div key={k.idbuilding}>Invoicing all up to date / No readings available to invoice for: {k.name}</div>
                }
            })
        }
    }


    if (props.missingUnits !== undefined && props.readyUnits !== undefined) {
        return (
            <div className="section-home">
                <h2 className="home-title">Home</h2>
                {/* <h2 className="home-title">Home (TESTING MODE)</h2>
                <p>(Invoices in testing mode can be continually re-generated for testing)</p> */}
                {props.statementData !== [] && props.statementData !== undefined && props.statementData !== null ?
                    <CSVLink
                        data={props.statementData}
                        filename={`${format(new Date(), "yyyy-MM-dd")}_statement.csv`}
                        className="btn btn-primary dlCSV"
                        target="_blank"
                    >
                        Download Statement
                    </CSVLink>
                    : null}
                {props.readyUnits.length > 0 ?
                    <div className="home-invoice-due-date-picker">
                        <div><label>Select due date</label></div>
                        <div><input type="date" value={dueDate} onChange={(e) => setDueDate(format(new Date(e.target.value), "yyyy-MM-dd"))} /></div>
                    </div> : null}
                {renderGenerateInvoicesCheck()}
                {props.readyUnits.length > 0 ?
                    <div>
                        <div className="home-missing-readings-title">READY TO INVOICE</div>
                        {renderReadyUnits()}
                    </div>
                    : null}
                {props.missingUnits.length > 0 ?
                    <div className="home-missing-readings-title">LAST MONTH MISSING READINGS</div> : null}
                {renderMissingUnits()}
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        invoices: state.invoicesTestGenerateGetReducer.invoices,
        missingUnits: state.missingUnitsGetReducer.missingUnits,
        readyUnits: state.readyUnitsGetReducer.readyUnits,
        buildingList: state.buildingGetReducer.buildingList,
        statementData: state.getStatementReducer.statementData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            generateTestInvoices: generateTestInvoices,
            retrieveMissingUnitReadings: retrieveMissingUnitReadings,
            retrieveReadyInvoiceUnitReadings: retrieveReadyInvoiceUnitReadings,
            getBuildingList: getBuildingList,
            retrieveStatementAll: retrieveStatementAll
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionHome);
