import { GET_MISSING_UNIT_READINGS_ACTION } from "../Actions/types";

const missingUnitsState = {
    missingUnits: [],
};

const missingUnitsGetReducer = (state = missingUnitsState, action) => {
    switch (action.type) {
        case GET_MISSING_UNIT_READINGS_ACTION:
            state = {
                ...state,
                missingUnits: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default missingUnitsGetReducer;
