/*
Authors: Joel Wilkinson
Details: SectionInvoices.js is the component for managing the
line items available that a unit can have by association.

Country Consulting ©2021
*/

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";

import { retrieveUnpaidAllInvoices, updateUnpaidInvoices } from "../../Actions/functions";

import "./Dashboard.css";

const SectionInvoices = (props) => {

    const [open, setOpen] = useState(null);
    const [amount, setAmount] = useState(0.00);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState((new Date().toISOString().split('T')[0]), "yyyy-mm-dd")

    const { retrieveUnpaidAllInvoices } = props;

    useEffect(() => {
        retrieveUnpaidAllInvoices();
        // eslint-disable-next-line
    }, []);

    const handleOpen = (id, receivedAmount) => {
        setTimeout(() => {
            setOpen(id);
            setAmount(receivedAmount);
        }, 500);
    };

    const handleClose = () => {
        setTimeout(() => {
            setOpen(null);
        }, 250);
        setTimeout(() => {
            setAmount(0.00);
            setChecked(false);
        }, 1000);
    };

    const updateInvoice = () => {
        props.updateUnpaidInvoices(open, amount, checked, date);
        setLoading(true);
        handleClose();
        setTimeout(() => {
            retrieveUnpaidAllInvoices();
        }, 1000);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }


    if (props.unpaidAllInvoices !== undefined) {
        return (
            <div className="section-invoices">
                <Modal
                    className="invoices-modal"
                    open={loading ? true : false}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={loading}>
                        <div className="invoices-loading"><CircularProgress className="secondary" size={"6em"} /></div>
                    </Fade>
                </Modal>
                {
                    props.unpaidAllInvoices.length > 0 ? props.unpaidAllInvoices.map((k, i) => {
                        return (
                            <div className="invoices-all-unpaid-render" key={k.html}>
                                <div onClick={() => handleOpen(k.id, k.amount)}>
                                    <p style={{ fontWeight: "bold" }}>{k.bldgName}</p>
                                    <p style={{ fontWeight: "bold" }}>{k.unitNum}</p>
                                    <p style={{ fontWeight: "bold" }}>Date of Notice</p>
                                    <p style={{ color: "white", fontSize: "24px" }}>{k.dateNotice}</p>
                                    <p style={{ fontWeight: "bold" }}>Recieved</p>
                                    <p style={{ color: "white", fontSize: "24px" }}>${k.amount} / (${k.requiredNormal}){k.requiredDiscount !== undefined && k.requiredDiscount !== null ? <span>  | ${k.requiredDiscount})</span> : null}</p>
                                </div>
                                <Modal
                                    className="invoices-modal"
                                    open={open === k.id ? true : false}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={open}>
                                        <div className="invoices-modal-open-wide">
                                            <p>Required Amount (Standard)</p>
                                            <p>${k.requiredNormal}</p>
                                            {k.requiredDiscount !== undefined && k.requiredDiscount !== null ?
                                                <div>
                                                    <p>Required Amount (Discount)</p>
                                                    <p>${k.requiredDiscount}</p>
                                                </div> : null}
                                            <p>Received Amount</p>
                                            <p>$<input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} /></p>
                                            <p>
                                                <p>Date of Payment</p> {/* Add the Date field here */}
                                                <p>
                                                    <input
                                                        type="date"
                                                        value={date}
                                                        onChange={(e) => setDate(e.target.value)}
                                                    />
                                                </p>
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={() => setChecked(checked ? false : true)}
                                                    color="primary"
                                                /> Is Fully Paid</p>
                                            <Button variant="contained" color="primary" onClick={() => updateInvoice()}>Update</Button>
                                            <div dangerouslySetInnerHTML={{ __html: k.html }} />
                                        </div>
                                    </Fade>
                                </Modal>
                            </div>
                        )
                    }) : null
                }
            </div>
        );
    } else {
        return (
            <p style={{ color: "white" }}>There has been a server-side error.</p>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        unpaidAllInvoices: state.invoicesUnpaidGetReducer.unpaidAllInvoices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            retrieveUnpaidAllInvoices: retrieveUnpaidAllInvoices,
            updateUnpaidInvoices: updateUnpaidInvoices
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionInvoices);
