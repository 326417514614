/*
Authors: Joel Wilkinson
Details: SectionDiscountRates.js is the component for managing the
available discount rates that a unit can have (for early payment).

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DiscountRates from "../DiscountRates/DiscountRates";

import "./Dashboard.css";

const SectionDiscountRates = () => {


    return (
        <div className="section-discount-rates">
            <DiscountRates />
        </div>
    );


}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDiscountRates);
