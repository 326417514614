/*
Authors: Joel Wilkinson
Details: Readings.js is the component that renders the meter readings associated
with a unit.

Country Consulting ©2021
*/

import React, { useState, useEffect, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";
import LatePaymentSelector from "./latePaymentSelector";

import "./Transactions.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function getCurrentFinancialYear() {
  var fiscalYearStart = 0;
  var fiscalYearEnd = 0;
  var today = new Date();
  var currentMonth = today.getMonth() + 1; // Adding 1 because getMonth returns a zero-based index

  if (currentMonth >= 7) { // July (7th month) or later
    fiscalYearStart = today.getFullYear();
    fiscalYearEnd = today.getFullYear() + 1;
  } else { // Before July
    fiscalYearStart = today.getFullYear() - 1;
    fiscalYearEnd = today.getFullYear();
  }

  return { startYear: fiscalYearStart, endYear: fiscalYearEnd };
}


const Readings = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [latePaymentData, setLatePaymentData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [transactionRecordedStatus, setTransactionRecordedStatus] =
    useState(false);
  const currentFinancialYears = getCurrentFinancialYear();
  const [financialYearStart, setFinancialYearStart] = useState(
    new Date(currentFinancialYears.startYear, 6, 1)
  );
  const [financialYearEnd, setFinancialYearEnd] = useState(
    new Date(currentFinancialYears.endYear, 5, 30)
  );

  function CustomToolbar(props) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="date-picker-container datePicker">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Financial Year Start"
              format="dd/MM/yyyy"
              value={props.financialYearStart}
              onChange={props.onFinancialYearStartChange}
            />
            <DatePicker
              label="Financial Year End"
              format="dd/MM/yyyy"
              value={props.financialYearEnd}
              onChange={props.onFinancialYearEndChange}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ marginLeft: "auto", marginRight: "20px" }}>
          {/* You can add any other toolbar elements here */}
        </div>
      </div>
    );
  }

  const postTransaction = async (transactionDetails) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`/transactions/customTransaction`, {
        transactionDetails,
      });
    } catch (error) {
      console.error("Error recording data:", error);
    } finally {
      setIsLoading(false);
      setTransactionRecordedStatus(true);
    }
  };

  const fetchData = async () => {

    try {
      const idunit = props.props.unitId;
      setIsLoading(true);
      // getCurrentFinancialYear();
      const financialYearStartFormatted =
        financialYearStart.getFullYear() +
        "-" +
        (financialYearStart.getMonth() + 1) +
        "-" +
        financialYearStart.getDate();
      const financialYearEndFormatted =
        financialYearEnd.getFullYear() +
        "-" +
        (financialYearEnd.getMonth() + 1) +
        "-" +
        financialYearEnd.getDate();
      // Get and set transactions
      const responseTransactions = await axios.get(
        `/transactions/getUnitTransactions/?startDate=${financialYearStartFormatted}&endDate=${financialYearEndFormatted}&idunit=${idunit}`
      );
      setTransactionData(responseTransactions.data);
      // Get and set late payment dropdown
      const responseLatePayments = await axios.get(
        `/transactions/getOverdue?idunit=${idunit}`
      );
      setLatePaymentData(responseLatePayments.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setTransactionRecordedStatus(false);
    // eslint-disable-next-line
  }, [financialYearStart, financialYearEnd, transactionRecordedStatus]);

  const columns = [
    { title: "Date", field: "date" },
    { title: "Description", type: "string", field: "description" },
    {
      title: "Charge",
      field: "charge",
      type: "currency",
      currencySetting: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      render: (rowData) => {
        if (rowData.charge === null || rowData.charge === undefined) {
          return "";
        } else {
          return `$${rowData.charge.toFixed(2)}`;
        }
      },
    },
    {
      title: "Payment",
      field: "payment",
      type: "currency",
      currencySetting: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      render: (rowData) => {
        if (rowData.payment === null || rowData.payment === undefined) {
          return "";
        } else {
          return `$${rowData.payment.toFixed(2)}`;
        }
      },
    },
    {
      title: "Balance Owing",
      field: "balance",
      type: "currency",
      currencySetting: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      render: (rowData) => {
        const balanceValue = rowData.balance || 0;
        const color = balanceValue <= 0 ? "green" : "red";

        return <span style={{ color }}>{`$${balanceValue.toFixed(2)}`}</span>;
      },
    },
  ];

  const generateCSVData = () => {
    const formattedData = transactionData.map((rowData) => ({
      Date: rowData.date,
      Description: rowData.description,
      Charge: rowData.charge !== null ? `$${rowData.charge.toFixed(2)}` : "",
      Payment: rowData.payment !== null ? `$${rowData.payment.toFixed(2)}` : "",
      "Balance Owing": `$${rowData.balance.toFixed(2)}`,
    }));
    setCsvData(formattedData);
  };

  return (
    <div className="transaction-widget">
      {isLoading ? (
        <div>Loading transactions...</div>
      ) : (
        <>
          <CSVLink
            onClick={generateCSVData}
            data={csvData}
            filename={"transactions.csv"}
            className="csv-download-button"
            target="_blank"
          >
            Download Transaction Statement <u>↓</u>
          </CSVLink>
          <LatePaymentSelector
            latePayments={latePaymentData}
            postTransaction={postTransaction}
          />
          <MaterialTable
            icons={tableIcons}
            title="Transactions"
            columns={columns}
            data={transactionData}
            components={{
              Toolbar: (props) => (
                <CustomToolbar
                  {...props}
                  financialYearStart={financialYearStart}
                  financialYearEnd={financialYearEnd}
                  onFinancialYearStartChange={setFinancialYearStart}
                  onFinancialYearEndChange={setFinancialYearEnd}
                />
              ),
            }}
            options={{
              filtering: true,
              search: false, // Disable the default search bar
              paging: true,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              serverPaging: true, // Enable server-side paging
            }}
          />
        </>
      )}
    </div>
  );
};

export default Readings;
