import { UPDATE_PEOPLE_ACTION } from "../Actions/types";

const peopleUpdateState = {
    peopleUpdated: "",
};

const peopleUpdateReducer = (state = peopleUpdateState, action) => {
    switch (action.type) {
        case UPDATE_PEOPLE_ACTION:
            state = {
                ...state,
                peopleUpdated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default peopleUpdateReducer;
