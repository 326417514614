/*
Authors: Joel Wilkinson
Details: SectionLineItems.js is the component for managing the
line items available that a unit can have by association.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LineItemTable from "../LineItemTable/LineItemTable";

import "./Dashboard.css";

const SectionLineItems = () => {

    return (
        <div className="section-line-items">
            <LineItemTable />
        </div>
    );


}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionLineItems);
