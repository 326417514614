import { ADD_DISCOUNT_RATES_ACTION } from "../Actions/types";

const discountRatesAddState = {
    discountRatesAdded: "",
};

const discountRatesAddReducer = (state = discountRatesAddState, action) => {
    switch (action.type) {
        case ADD_DISCOUNT_RATES_ACTION:
            state = {
                ...state,
                discountRatesAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default discountRatesAddReducer;
