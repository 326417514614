import { GET_ACTIVE_LINE_ITEMS_ACTION } from "../Actions/types";

const activeLineItemsGetState = {
    activeLineItemsList: [],
};

const activeLineItemsGetReducer = (state = activeLineItemsGetState, action) => {
    switch (action.type) {
        case GET_ACTIVE_LINE_ITEMS_ACTION:
            state = {
                ...state,
                activeLineItemsList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default activeLineItemsGetReducer;
