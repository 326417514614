/*
Authors: Joel Wilkinson
Details: FormAddBuilding.js is the component that renders the add
building form.

Country Consulting ©2021
*/

import React, { useState, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { updateBuilding, getBuildingList } from "../../Actions/functions";

import "./Building.css";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const FormUpdateBuilding = (props) => {

    const [columns,] = useState([
        {
            title: "Building Name",
            field: "name",
        },
        {
            title: "Street",
            field: "street",
        },
        {
            title: "Suburb",
            field: "suburb",
        },
        {
            title: "Post Code",
            field: "postCode",
            type: 'numeric'
        },
        {
            title: "State",
            field: "state",
            lookup: { "QLD": "QLD", "NSW": "NSW", "VIC": "VIC", "NT": "NT", "WA": "WA", "SA": "SA", "ACT": "ACT", "TAS": "TAS", },
        },
        {
            title: "NMI",
            field: "nmi",
        },
    ]);

    //const [data, setData] = useState(props.buildingList);

    if (props.buildingList !== null && props.buildingList !== undefined) {
        return (
            <div style={{ width: "100%" }}>
                <div>
                    <button className="form-btn" onClick={() => props.props.reset(false)}>Close</button>
                </div>
                <div className="form-update-building">
                    <MaterialTable
                        icons={tableIcons}
                        title="Update Building Info"
                        columns={columns}
                        data={props.buildingList}
                        options={{
                            showTitle: false,
                            search: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 25, 50, 100],
                            fontSize: 14,
                            rowStyle: (rowData) => ({
                                backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
                            }),
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        props.updateBuilding(newData.idbuilding, newData.name, newData.street, newData.suburb, newData.postCode, newData.state, newData.nmi);
                                        setTimeout(() => {
                                            props.getBuildingList();
                                        }, 2000);

                                        resolve();
                                    }, 1000)
                                }),
                        }}
                    />
                </div>
            </div >
        );
    } else {
        return (
            <div>Loading Update Buildings Table...</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        buildingList: state.buildingGetReducer.buildingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBuilding: updateBuilding,
            getBuildingList: getBuildingList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdateBuilding);