import { GET_UNPAID_INVOICES_ACTION, GET_UNPAID_ALL_INVOICES_ACTION } from "../Actions/types";

const invoicesUnpaidGetState = {
    unpaidInvoices: [],
    unpaidAllInvoices: [],
};

const invoicesUnpaidGetReducer = (state = invoicesUnpaidGetState, action) => {
    switch (action.type) {
        case GET_UNPAID_INVOICES_ACTION:
            state = {
                ...state,
                unpaidInvoices: action.payload
            };
            return state;
        case GET_UNPAID_ALL_INVOICES_ACTION:
            state = {
                ...state,
                unpaidAllInvoices: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default invoicesUnpaidGetReducer;
