/*
Authors: Joel Wilkinson
Details: DiscountRates.js renders the table which handles
the discount rates.

Country Consulting ©2021
*/

import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getDiscountRates, addDiscountRate, updateDiscountRate } from "../../Actions/functions";

import "./DiscountRates.css";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const DiscountRates = (props) => {

    const [columnData] = useState([
        { title: "Name", field: "name" },
        {
            title: "Rate (as %)", field: "rate", type: "numeric"
        },
    ])

    const [seniorRate, setSeniorRate] = useState(props.discountRatesSenior.senior_rate);
    const [seniorDetails,] = useState(props.discountRatesSenior);
    const [submitted, setSubmitted] = useState(false);

    const { getDiscountRates } = props;

    useEffect(() => {
        getDiscountRates();
        // eslint-disable-next-line
    }, []);

    const updateSeniorRate = () => {
        setSubmitted(true);
        setTimeout(() => {
            setSubmitted(false);
        }, 2500);
        props.updateDiscountRate(seniorDetails.iddiscount_rate, seniorDetails.name, seniorDetails.rate, seniorRate);
        props.getDiscountRates();
    }

    return (
        <div className="discount-rates-container">
            <MaterialTable
                icons={tableIcons}
                title="Discount Rates"
                columns={columnData}
                data={props.discountRatesList}
                options={{
                    search: true,
                    pageSize: 8,
                    rowStyle: (rowData) => ({
                        backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
                    }),
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                props.addDiscountRate(newData.name, newData.rate);
                                props.getDiscountRates();
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                props.updateDiscountRate(newData.iddiscount_rate, newData.name, newData.rate);
                                props.getDiscountRates();
                                resolve();
                            }, 1000)
                        }),
                }}
            />
            <div>{seniorRate !== undefined ?
                <div>
                    <div style={{ fontSize: "1.5em", marginTop: "5%", fontWeight: "bold" }}>Senior Rate</div>
                    <input type="number" value={seniorRate} onChange={(e) => setSeniorRate(e.target.value)} />
                    <div onClick={() => updateSeniorRate()} className="senior-rate-update-btn">Update rate</div>
                    <CircularProgress className={submitted ? "secondary senior-submit-load" : "senior-submit-load-hide"} size={"5em"} />
                </div>
                : null}</div>
        </div>
    );


}

const mapStateToProps = (state) => {
    return {
        discountRatesList: state.discountRatesGetReducer.discountRatesList,
        discountRatesSenior: state.discountRatesGetReducer.discountRatesSenior,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDiscountRates: getDiscountRates,
            addDiscountRate: addDiscountRate,
            updateDiscountRate: updateDiscountRate,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountRates);
