import { GET_READINGS_ACTION, RESET_READINGS_ACTION } from "../Actions/types";
import { add } from "date-fns";

const readingsGetState = {
    readingList: [],
    startVal: 0,
    startDate: new Date().toISOString()
};

const readingsGetReducer = (state = readingsGetState, action) => {
    switch (action.type) {
        case GET_READINGS_ACTION:
            // let setStartVal = 0;
            // let setStartDate = new Date().toISOString();
            // console.log(action.payload[0])
            // if (action.payload[0] !== undefined) {
            //     setStartVal = action.payload[0].readingEnd;
            //     setStartDate = add(new Date(action.payload[0].dateOfReadingEnd), { days: 1 });
            // }

            state = {
                ...state,
                readingList: action.payload.length > 0 ? action.payload[0] : action.payload,
                startVal: action.payload.length > 0 ? action.payload[1] : 0,
                startDate: action.payload.length > 0 ? add(new Date(action.payload[2]), { days: 1 }) : add(new Date(), { days: 1 }),
            };
            return state;
        case RESET_READINGS_ACTION:
            state = {
                ...state,
                readingList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default readingsGetReducer;
