import { DELETE_ACTIVE_LINE_ITEMS_ACTION } from "../Actions/types";

const activeLineItemsDeleteState = {
    activeLineItemDeleted: "",
};

const activeLineItemsDeleteReducer = (state = activeLineItemsDeleteState, action) => {
    switch (action.type) {
        case DELETE_ACTIVE_LINE_ITEMS_ACTION:
            state = {
                ...state,
                activeLineItemDeleted: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default activeLineItemsDeleteReducer;
