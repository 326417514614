/*
Authors: Joel Wilkinson
Details: Home.js is the component that renders the login (aka first) page
to the user.

Country Consulting ©2021
*/

import React, { useState, useEffect, } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import { loginUser } from "../../Actions/functions";
import { loginUserTFA } from "../../Actions/functions";
import { loginFailure } from "../../Actions/functions";
import { resetState } from "../../Actions/functions";
import { verifyAuthentication, userTimeout } from "../../Actions/functions";

import "./Home.css";
import CCLogo from "../../Media/cc.png";

const Home = (props) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submittedCode, setSubmittedCode] = useState(false);

  const { verifyAuthentication, authenticated, username, secret, loginFailure, sessionExists, userTimeout, resetState } = props;

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (user !== "" && password !== "") {
      props.loginUser(user, password);
    }
  }

  const handleSubmitCode = (e) => {
    e.preventDefault();

    setSubmittedCode(true);
    if (user !== "" && password !== "" && code !== "") {
      props.loginUserTFA(user, password, code);
    }
  }

  useEffect(() => {
    verifyAuthentication();
    if (!sessionExists) {
      userTimeout();
    }
    resetState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    verifyAuthentication();
  }, [authenticated, verifyAuthentication]);

  useEffect(() => {
    if (username === "N/A" || secret === "fail") {
      if (submitted) {
        setSubmitted(false);
        setSubmittedCode(false);
        setCode("");
      }
      setTimeout(() => {
        loginFailure();
      }, 3000);
    }
  }, [username, secret, loginFailure, submitted, setSubmitted, setSubmittedCode, setCode])

  if (props.sessionExists && props.authenticated) {
    return <Redirect to="/dashboard" />;
  }
  else if (props.secret === "" || props.secret === false) {
    return (
      <div>
        <div className="login-form">
          <form name="login" onSubmit={handleSubmit}>
            <div>
              <img id="logo" src={CCLogo} alt="Country Consulting Logo"></img>
              <div className="login-title">Utility Billing</div>
            </div>
            <div>
              <input
                type="text"
                className="form-field"
                name="username"
                placeholder="username"
                value={user}
                onChange={e => setUser(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                className="form-field"
                name="password"
                placeholder="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="login-btn">
              <button className="btn btn-primary">Login</button>
              {submitted ? (
                <CircularProgress className="spinning-icon" />
              ) : (
                ""
              )}
            </div>
            {props.username === "N/A" ? (
              <div className="login-error">
                Username or password was incorrect
              </div>
            ) : (
              <div className="login-error-hide"></div>
            )}
            {props.username !== "N/A" &&
              props.username !== "" &&
              props.authenticated ? (
              <div className="login-success">Logging in...</div>
            ) : (
              <div className="login-success-hide"></div>
            )}
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="login-form">
          <form name="login" onSubmit={handleSubmitCode}>
            <div>
              <img id="logo" src={CCLogo} alt="Country Consulting Logo"></img>
            </div>
            <div>
              <input
                type="text"
                className="form-field"
                name="username"
                placeholder="Username..."
                value={user}
                onChange={e => setUser(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                className="form-field"
                name="password"
                placeholder="Password..."
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div>
              <input
                type="code"
                className="form-field"
                name="code"
                placeholder="Code..."
                value={code}
                onChange={e => setCode(e.target.value)}
              />
            </div>
            <div className="login-btn">
              <button className="btn btn-primary">Login</button>
              {submittedCode ? (
                <CircularProgress className="spinning-icon" />
              ) : (
                ""
              )}
            </div>
            {props.username === "N/A" ? (
              <div className="login-error">
                Username, password, or 2FA code was incorrect
              </div>
            ) : (
              <div className="login-error-hide"></div>
            )}
            {props.username !== "N/A" &&
              props.username !== "" &&
              props.authenticated &&
              props.secret === true ? (
              <div className="login-success">Logging in...</div>
            ) : (
              <div className="login-success-hide"></div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    username: state.loginReducer.username,
    sessionExists: state.checkAuthReducer.sessionExists,
    secret: state.loginReducer.secret,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginUser: loginUser,
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      loginFailure: loginFailure,
      loginUserTFA: loginUserTFA,
      resetState: resetState,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
