import { ADD_LINE_ITEMS_ACTION } from "../Actions/types";

const lineItemsAddState = {
    lineItemAdded: "",
};

const lineItemsAddReducer = (state = lineItemsAddState, action) => {
    switch (action.type) {
        case ADD_LINE_ITEMS_ACTION:
            state = {
                ...state,
                lineItemAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default lineItemsAddReducer;
