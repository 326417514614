/*
Authors: Joel Wilkinson
Details: Dashboard.js is the component for rendering the dashboard page (first page post-login).
It checks that the user is authenticated, otherwise the user is sent to the login screen (all
  pages check for this, including all requests to the server).

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import LinearProgress from "@material-ui/core/LinearProgress";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";

import SectionHome from "./SectionHome";
import SectionBuilding from "./SectionBuilding";
import SectionDiscountRates from "./SectionDiscountRates";
import SectionLineItems from "./SectionLineItems";
import SectionInvoices from "./SectionInvoices";

import "./Dashboard.css";

const Dashboard = (props) => {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [viewingHome, setViewingHome] = useState(true);
  const [viewingBuildings, setViewingBuildings] = useState(false);
  const [viewingDiscountRates, setViewingDiscountRates] = useState(false);
  const [viewingLineItems, setViewingLineItems] = useState(false);
  const [viewingInvoices, setViewingInvoices] = useState(false);

  const { documentHeight, verifyAuthentication, userTimeout, sessionExists } = props;

  useEffect(() => {
    verifyAuthentication();
    if (!sessionExists) {
      userTimeout();
    }

    setTimeout(() => {
      setLoadingComplete(true);
    }, 1500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    documentHeight(window.document.body.offsetHeight);
  }, [documentHeight]);

  const handleHome = () => {
    setViewingHome(true);
    setViewingBuildings(false);
    setViewingDiscountRates(false);
    setViewingLineItems(false);
    setViewingInvoices(false);
  }

  const handleBuildings = () => {
    setViewingHome(false);
    setViewingBuildings(true);
    setViewingDiscountRates(false);
    setViewingLineItems(false);
    setViewingInvoices(false);
  }

  const handleDiscountRates = () => {
    setViewingHome(false);
    setViewingBuildings(false);
    setViewingDiscountRates(true);
    setViewingLineItems(false);
    setViewingInvoices(false);
  }

  const handleLineItems = () => {
    setViewingHome(false);
    setViewingBuildings(false);
    setViewingDiscountRates(false);
    setViewingLineItems(true);
    setViewingInvoices(false);
  }

  const handleInvoices = () => {
    setViewingHome(false);
    setViewingBuildings(false);
    setViewingDiscountRates(false);
    setViewingLineItems(false);
    setViewingInvoices(true);
  }

  if (!props.authenticated || !props.sessionExists) {
    return <Redirect to="/login" />;
  }
  if (loadingComplete) {
    return (
      <div className="dashboard-body">
        <NavBar />
        <div className="dashboard-background">
          <div className="dashboard-outer">
            <SideBar props={{ home: handleHome, building: handleBuildings, discountRates: handleDiscountRates, lineItems: handleLineItems, invoices: handleInvoices }} />
            <div className="dashboard-inner">
              {viewingHome ?
                <SectionHome /> :
                viewingBuildings ?
                  <SectionBuilding /> :
                  viewingDiscountRates ?
                    <SectionDiscountRates /> :
                    viewingLineItems ?
                      <SectionLineItems /> :
                      viewingInvoices ?
                        <SectionInvoices /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    !loadingComplete
  ) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loadingProgress">
          <LinearProgress />
        </div>
        <p>Loading...</p>
        <p>Please Wait A Moment...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    name: state.loginReducer.name,
    accountType: state.loginReducer.accountType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
