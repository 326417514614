import { GET_PEOPLE_ACTION, RESET_PEOPLE_ACTION } from "../Actions/types";

const peopleGetState = {
    peopleList: [],
};

const peopleGetReducer = (state = peopleGetState, action) => {
    switch (action.type) {
        case GET_PEOPLE_ACTION:
            state = {
                ...state,
                peopleList: action.payload
            };
            return state;
        case RESET_PEOPLE_ACTION:
            state = {
                ...state,
                peopleList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default peopleGetReducer;
