/*
Authors: Joel Wilkinson
Details: Building.js is the component that renders a building with
an inner component (Unit).

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getUnitList, resetUnitList } from "../../Actions/functions";

import "./Building.css";

const Building = (props) => {

  const viewUnits = () => {
    props.resetUnitList();
    props.getUnitList(props.props.id);
    props.props.clicked(true, props.props.id);
  }

  return (
    <div
      className="building-widget"
      onClick={() => viewUnits()}
    >
      <div>{props.props.buildingName}</div>
      <div style={{ fontSize: "0.5em", color: "grey" }}>{props.props.street}</div>
      <div style={{ fontSize: "0.5em", color: "grey" }}>{props.props.suburb}</div>
      <div style={{ fontSize: "0.5em", color: "grey" }}>{props.props.postCode} {props.props.state}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUnitList: getUnitList,
      resetUnitList: resetUnitList
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Building);
