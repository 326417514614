/*
Authors: Joel Wilkinson
Details: Readings.js is the component that renders the meter readings associated
with a unit.

Country Consulting ©2021
*/

import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";

import { getReadingsList, addReadings, updateReadings } from "../../Actions/functions";

import "./Readings.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Readings = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [columnData, setColumnData] = useState([
    { title: "Start", field: "readingStart", initialEditValue: props.startVal.toString() },
    { title: "End", field: "readingEnd" },
    {
      title: "Start Date", field: "dateOfReadingStart", type: 'date', initialEditValue: props.startDate.toString(),
      render: rowData => "" + format(parseISO(new Date(rowData.dateOfReadingStart).toISOString()), "dd.MM.yyyy") + "",
    },
    {
      title: "End Date", field: "dateOfReadingEnd", type: 'date',
      render: rowData => "" + format(parseISO(new Date(rowData.dateOfReadingEnd).toISOString()), "dd.MM.yyyy") + "",
    },
    { title: "Duration", field: "numOfDays", editable: 'never' },
    { title: "Invoiced", field: "invoiced", editable: 'never', lookup: { 1: "INVOICED", 0: "NOT INVOICED" } }
  ]);

  const { unitId } = props.props;
  const { getReadingsList } = props;

  useEffect(() => {
    getReadingsList(unitId);

    setTimeout(() => {
      setColumnData([
        { title: "Start", field: "readingStart", initialEditValue: props.startVal.toString() },
        { title: "End", field: "readingEnd" },
        {
          title: "Start Date", field: "dateOfReadingStart", type: 'date', initialEditValue: props.startDate.toString(),
          render: rowData => "" + format(parseISO(new Date(rowData.dateOfReadingStart).toISOString()), "dd.MM.yyyy") + "",
        },
        {
          title: "End Date", field: "dateOfReadingEnd", type: 'date',
          render: rowData => "" + format(parseISO(new Date(rowData.dateOfReadingEnd).toISOString()), "dd.MM.yyyy") + "",
        },
        { title: "Duration", field: "numOfDays", editable: 'never' },
        { title: "Invoiced", field: "invoiced", editable: 'never', lookup: { 1: "INVOICED", 0: "NOT INVOICED" } }
      ])
      setLoaded(true);
    }, 1500);

    return () => {
      setColumnData([]);
    }

    // eslint-disable-next-line
  }, []);

  if (props.readingList !== undefined || props.readingList !== null) {
    return (
      <div className="reading-widget">
        {!loaded ? <p style={{ fontSize: "2em" }}>Loading Data...</p> :
          <MaterialTable
            icons={tableIcons}
            title="Readings"
            columns={columnData}
            data={props.readingList}
            options={{
              search: true,
              pageSize: 5,
              // pageSizeOptions: [10, 25, 50, 100],
              filtering: true,
              rowStyle: (rowData) => ({
                backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
              }),
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {

                  const dateOfReadingStart = format(parseISO(new Date(newData.dateOfReadingStart).toISOString()), "yyyy-MM-dd");
                  const dateOfReadingEnd = format(parseISO(new Date(newData.dateOfReadingEnd).toISOString()), "yyyy-MM-dd");
                  setTimeout(() => {
                    if (parseInt(newData.readingEnd) > parseInt(newData.readingStart)) {
                      props.addReadings(props.props.unitId, newData.readingStart, newData.readingEnd, dateOfReadingStart, dateOfReadingEnd);
                    }
                    setTimeout(() => {
                      props.getReadingsList(props.props.unitId);
                    }, 1000);

                    resolve();
                  }, 1000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dateOfReadingStart = format(parseISO(new Date(newData.dateOfReadingStart).toISOString()), "yyyy-MM-dd");
                  const dateOfReadingEnd = format(parseISO(new Date(newData.dateOfReadingEnd).toISOString()), "yyyy-MM-dd");
                  setTimeout(() => {
                    if (newData.invoiced === 0 && newData.readingEnd > newData.readingStart) {
                      props.updateReadings(newData.idreading, newData.readingStart, newData.readingEnd, dateOfReadingStart, dateOfReadingEnd);
                    }
                    setTimeout(() => {
                      props.getReadingsList(props.props.unitId);
                    }, 1000);

                    resolve();
                  }, 1000)
                }),
            }}
          />}
      </div>
    );
  } else {
    return (
      <div>Loading Readings...</div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    readingList: state.readingsGetReducer.readingList,
    startVal: state.readingsGetReducer.startVal,
    startDate: state.readingsGetReducer.startDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getReadingsList: getReadingsList,
    addReadings: addReadings,
    updateReadings: updateReadings
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Readings);
