import { ADD_BUILDING_ACTION } from "../Actions/types";

const buildingAddState = {
    buildingAdded: "",
};

const buildingAddReducer = (state = buildingAddState, action) => {
    switch (action.type) {
        case ADD_BUILDING_ACTION:
            state = {
                ...state,
                buildingAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default buildingAddReducer;
