import { UPDATE_DISCOUNT_RATES_ACTION } from "../Actions/types";

const discountRatesUpdateState = {
    discountRatesUpdated: "",
};

const discountRatesUpdateReducer = (state = discountRatesUpdateState, action) => {
    switch (action.type) {
        case UPDATE_DISCOUNT_RATES_ACTION:
            state = {
                ...state,
                discountRatesUpdated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default discountRatesUpdateReducer;
