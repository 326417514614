import { GET_UNIT_INVOICE_READY_READINGS_ACTION } from "../Actions/types";

const readyInvoiceReadingState = {
    readyUnits: [],
};

const readyUnitsGetReducer = (state = readyInvoiceReadingState, action) => {
    switch (action.type) {
        case GET_UNIT_INVOICE_READY_READINGS_ACTION:
            state = {
                ...state,
                readyUnits: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default readyUnitsGetReducer;
