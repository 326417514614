/*
Authors: Joel Wilkinson
Details: Person.js is the component that renders the people associated
with a unit.

Country Consulting ©2021
*/

import React, { useState, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { getPeopleList, addPeople, updatePeople } from "../../Actions/functions";

import "./People.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const People = (props) => {
  const [columnData] = useState([
    { title: "First Name", field: "name" },
    { title: "Surname", field: "surname" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Primary Contact", field: "isPrimaryContact", lookup: { 1: "YES", 0: "NO" }, },
  ])

  if (props.peopleList !== undefined || props.peopleList !== null) {
    return (
      <div className="people-widget">
        <MaterialTable
          icons={tableIcons}
          title="Tenants"
          columns={columnData}
          data={props.peopleList}
          options={{
            search: true,
            pageSize: 5,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
            }),
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  props.addPeople(props.props.unitId, newData.name, newData.surname, newData.phone, newData.email, newData.isPrimaryContact);
                  props.getPeopleList(props.props.unitId);
                  resolve();
                }, 1000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  props.updatePeople(newData.idpeople, newData.name, newData.surname, newData.phone, newData.email, newData.isPrimaryContact);
                  props.getPeopleList(props.props.unitId);
                  resolve();
                }, 1000)
              }),
          }}
        />
      </div>
    );
  } else {
    return (
      <div>Loading People...</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    peopleList: state.peopleGetReducer.peopleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getPeopleList: getPeopleList,
    addPeople: addPeople,
    updatePeople: updatePeople
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(People);
