import { GET_UNIT_ACTION, RESET_UNIT_ACTION } from "../Actions/types";

const unitGetState = {
    unitList: [],
};

const unitGetReducer = (state = unitGetState, action) => {
    switch (action.type) {
        case GET_UNIT_ACTION:
            state = {
                ...state,
                unitList: action.payload
            };
            return state;
        case RESET_UNIT_ACTION:
            state = {
                ...state,
                unitList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default unitGetReducer;
