import { ADD_PEOPLE_ACTION } from "../Actions/types";

const peopleAddState = {
    peopleAdded: "",
};

const peopleAddReducer = (state = peopleAddState, action) => {
    switch (action.type) {
        case ADD_PEOPLE_ACTION:
            state = {
                ...state,
                peopleAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default peopleAddReducer;
