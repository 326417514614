import { UPDATE_READINGS_ACTION } from "../Actions/types";

const readingsUpdateState = {
    readingUpdated: "",
};

const readingsUpdateReducer = (state = readingsUpdateState, action) => {
    switch (action.type) {
        case UPDATE_READINGS_ACTION:
            state = {
                ...state,
                readingUpdated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default readingsUpdateReducer;
