/*
Authors: Joel Wilkinson
Details: Invoices.js is the component that renders the invoices associated
with a unit.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

import LineItem from "../LineItem/LineItem";

import { getActiveLineItems, getNonActiveLineItems, addActiveLineItem, deleteActiveLineItem, updateUnpaidInvoices, retrievePastInvoices, retrieveUnpaidInvoices, retrieveStatementUnit } from "../../Actions/functions";

import "./Invoices.css";


const Invoices = (props) => {

  const [showItems, setShowItems] = useState(false);
  const [open, setOpen] = useState(null);
  const [amount, setAmount] = useState(0.00);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState((new Date().toISOString().split('T')[0]), "yyyy-mm-dd")

  const handleOpen = (id, receivedAmount) => {
    setTimeout(() => {
      setOpen(id);
      setAmount(receivedAmount);
    }, 500);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpen(null);
    }, 250);
    setTimeout(() => {
      setAmount(0.00);
      setChecked(false);
    }, 1000);
  };

  const updateInvoice = () => {
    props.updateUnpaidInvoices(open, amount, checked, date);
    setLoading(true);
    handleClose();
    setTimeout(() => {
      props.retrievePastInvoices(props.props.unitId);
      props.retrieveUnpaidInvoices(props.props.unitId);
    }, 250);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }

  const { getNonActiveLineItems, getActiveLineItems } = props;
  const { unitId } = props.props;

  useEffect(() => {
    getActiveLineItems(unitId);
    getNonActiveLineItems(unitId);
    setTimeout(() => {
      setShowItems(true);
    }, 1000);

    return () => {
      setShowItems(false);
    }

  }, [unitId, getNonActiveLineItems, getActiveLineItems]);

  useEffect(() => {
    props.retrieveStatementUnit(props.props.unitId);
  }, [])

  const renderAddedLineItems = () => {
    return props.activeLineItemsList.map((k, i) => {
      return <LineItem key={i} props={{ type: k.type, rate: k.rate, description: k.description, unitId: unitId, itemId: k.iditem, id: k.idline_item_active, handleLineItem: props.deleteActiveLineItem }} />;
    })
  }

  const renderNotAddedLineItems = () => {
    return props.nonActiveLineItemsList.map((k, i) => {
      return <LineItem key={i} props={{ type: k.type, rate: k.rate, description: k.description, unitId: unitId, itemId: k.iditem, id: null, handleLineItem: props.addActiveLineItem }} />;
    })
  }

  if (props.prevInvoices !== undefined) {
    return (
      <div className="invoices-widget">
        <Modal
          className="invoices-modal"
          open={loading ? true : false}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={loading}>
            <div className="invoices-loading"><CircularProgress className="secondary" size={"6em"} /></div>
          </Fade>
        </Modal>
        <div className="invoices-title">Current Invoice Information</div>
        <div>Line Items</div>
        <div className="invoices-line-items">
          <div className="invoices-added">
            <div>Added</div>
            {showItems ? renderAddedLineItems() : "Loading..."}
          </div>
          <div className="invoices-not-added">
            <div>Not Added</div>
            {showItems ? renderNotAddedLineItems() : "Loading..."}
          </div>
        </div>
        {props.statementData !== [] && props.statementData !== undefined && props.statementData !== null ?
          <CSVLink
            data={props.statementData}
            filename={`${format(new Date(), "yyyy-MM-dd")}_statement.csv`}
            className="btn btn-primary dlCSV"
            target="_blank"
          >
            Download Statement
          </CSVLink>
          : null}
        <div className="invoices-title">Unpaid Invoices</div>
        {
          props.unpaidInvoices.length > 0 && showItems ? props.unpaidInvoices.map((k, i) => {
            return (
              <div key={k.html} style={{ width: "100%" }}>
                <div className="invoices-unpaid-render" dangerouslySetInnerHTML={{ __html: k.html }} onClick={() => handleOpen(k.id, k.amount.toFixed(2))} />
                <Modal
                  className="invoices-modal"
                  open={open === k.id ? true : false}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className="invoices-modal-open">
                      <p>Required Amount (Standard)</p>
                      <p>${k.requiredNormal}</p>
                      {k.requiredDiscount !== undefined && k.requiredDiscount !== null ?
                        <div>
                          <p>Required Amount (Discount)</p>
                          <p>${k.requiredDiscount}</p>
                        </div> : null}
                      <p>Received Amount</p>
                      <p>$<input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} /></p>
                      <p>
                        <p>Date of Payment</p>
                        <p>
                          <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </p>
                        <Checkbox
                          checked={checked}
                          onChange={() => setChecked(checked ? false : true)}
                          color="primary"
                        /> Is Fully Paid</p>
                      <Button variant="contained" color="primary" onClick={() => updateInvoice()}>Update</Button>
                    </div>
                  </Fade>
                </Modal>
              </div>
            )
          }) : !showItems ? "Loading unpaid invoices..." : "There are no unpaid invoices."
        }
        <div className="invoices-title">Previous Completed Invoices</div>
        {
          props.prevInvoices.length > 0 && showItems ? props.prevInvoices.map((k, i) => {
            return <div style={{ width: "60%", margin: "0 auto", fontSize: "14px", marginBottom: "2%", backgroundColor: "pink" }} key={i} dangerouslySetInnerHTML={{ __html: k }} />
          }) : !showItems ? "Loading previous invoices..." : "There are no previous invoices."
        }
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    activeLineItemsList: state.activeLineItemsGetReducer.activeLineItemsList,
    nonActiveLineItemsList: state.nonActiveLineItemsGetReducer.nonActiveLineItemsList,
    lineItemsList: state.lineItemsGetReducer.lineItemsList,
    prevInvoices: state.invoicesPreviousGetReducer.prevInvoices,
    unpaidInvoices: state.invoicesUnpaidGetReducer.unpaidInvoices,
    statementData: state.getStatementReducer.statementData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getActiveLineItems: getActiveLineItems,
    getNonActiveLineItems: getNonActiveLineItems,
    addActiveLineItem: addActiveLineItem,
    deleteActiveLineItem: deleteActiveLineItem,
    updateUnpaidInvoices: updateUnpaidInvoices,
    retrievePastInvoices: retrievePastInvoices,
    retrieveUnpaidInvoices: retrieveUnpaidInvoices,
    retrieveStatementUnit: retrieveStatementUnit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
