/*
Authors: Joel Wilkinson
Details: UnitManagement.js is the component that renders the data associated
with a unit.

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import People from "../People/People";
import Readings from "../Readings/Readings";
import Invoices from "../Invoices/Invoices";
import Transactions from "../Transactions/Transactions"

import { retrievePastInvoices, retrieveUnpaidInvoices, getReadingsList } from "../../Actions/functions";

import "./UnitManagement.css";

const UnitManagement = (props) => {

  const [viewingPeople, setViewingPeople] = useState(true);
  const [viewingReadings, setViewingReadings] = useState(false);
  const [viewingInvoices, setViewingInvoices] = useState(false);
  const [viewingTransactions, setViewingTransactions] = useState(false);

  // Switch between the widgets
  // 0: tenants
  // 1: meter readings
  // 2: invoices
  const switchWidgets = (val) => {
    if (val === 0) {
      setViewingPeople(true);
      setViewingReadings(false);
      setViewingInvoices(false);
      setViewingTransactions(false);
    } else if (val === 1) {
      setViewingPeople(false);
      setViewingReadings(false);
      setViewingInvoices(true);
      setViewingTransactions(false);
      props.retrievePastInvoices(props.props.unitId);
      props.retrieveUnpaidInvoices(props.props.unitId);
    } else if (val === 2) {
      props.getReadingsList(props.props.unitId);
      setTimeout(() => {
        setViewingPeople(false);
        setViewingReadings(true);
        setViewingInvoices(false);
        setViewingTransactions(false);
      }, 350);
    } else if (val === 3) {
      setViewingPeople(false);
      setViewingReadings(false);
      setViewingInvoices(false);
      setViewingTransactions(true);
      // Retrieve Transaction Data
    }
  };

  return (
    <div className="unit-management-widget">
      <button
        className="btn btn-return"
        onClick={() => props.props.clicked(false)}
      >
        Return To Units
      </button>
      <div className="unit-management-buttons-row">
        <button
          className="btn unit-management-button"
          onClick={() => switchWidgets(0)}
        >
          Tenants
        </button>
        <button
          className="btn unit-management-button"
          onClick={() => switchWidgets(1)}
        >
          Invoices
        </button>
        <button
          className="btn unit-management-button"
          onClick={() => switchWidgets(2)}
        >
          Readings
        </button>
        <button
          className="btn unit-management-button"
          onClick={() => switchWidgets(3)}
        >
          Transactions
        </button>
      </div>
      <div style={{ width: "100%" }}>
        {viewingPeople === true ? <People props={{ unitId: props.props.unitId }} /> : null}
        {viewingReadings === true ? <Readings props={{ unitId: props.props.unitId }} /> : null}
        {viewingInvoices === true ? <Invoices props={{ unitId: props.props.unitId }} /> : null}
        {viewingTransactions === true ? <Transactions props={{ unitId: props.props.unitId }} /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    retrievePastInvoices: retrievePastInvoices,
    retrieveUnpaidInvoices: retrieveUnpaidInvoices,
    getReadingsList: getReadingsList
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitManagement);
