import { GET_BUILDING_ACTION } from "../Actions/types";

const buildingGetState = {
    buildingList: [],
};

const buildingGetReducer = (state = buildingGetState, action) => {
    switch (action.type) {
        case GET_BUILDING_ACTION:
            state = {
                ...state,
                buildingList: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default buildingGetReducer;
