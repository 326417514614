/*
Authors: Joel Wilkinson
Details: Index.js is the main component of the project
which handles the setup of Redux.

Country Consulting ©2020
*/

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import Loading from "./Components/Loading/Loading";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const persistConfig = {
  key: "root",
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer, applyMiddleware(thunk));

const persister = persistStore(store);

render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persister}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
