import { ADD_READINGS_ACTION } from "../Actions/types";

const readingsAddState = {
    readingAdded: "",
};

const readingsAddReducer = (state = readingsAddState, action) => {
    switch (action.type) {
        case ADD_READINGS_ACTION:
            state = {
                ...state,
                readingAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default readingsAddReducer;
