import { ADD_ACTIVE_LINE_ITEMS_ACTION } from "../Actions/types";

const activeLineItemsAddState = {
    activeLineItemAdded: "",
};

const activeLineItemsAddReducer = (state = activeLineItemsAddState, action) => {
    switch (action.type) {
        case ADD_ACTIVE_LINE_ITEMS_ACTION:
            state = {
                ...state,
                activeLineItemAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default activeLineItemsAddReducer;
