import axios from "axios";
import {
  POST_LOGIN_ACTION,
  FAILED_LOGIN_ACTION,
  CHECK_LOGGEDIN_ACTION,
  USER_TIMEOUT_ACTION,
  UPDATE_PASSWORD_ACTION,
  GET_TFA_ACTION,
  POST_TFA_ACTION,
  POST_TFALOGIN_ACTION,
  RESET_STATE_ACTION,
  GET_DOCUMENT_HEIGHT_ACTION,
  WAITING_SERVER_RESPONSE_ACTION,
  GET_BUILDING_ACTION,
  ADD_BUILDING_ACTION,
  UPDATE_BUILDING_ACTION,
  GET_UNIT_ACTION,
  RESET_UNIT_ACTION,
  GET_DISCOUNT_RATES_ACTION,
  ADD_UNIT_ACTION,
  UPDATE_UNIT_ACTION,
  GET_PEOPLE_ACTION,
  RESET_PEOPLE_ACTION,
  ADD_PEOPLE_ACTION,
  UPDATE_PEOPLE_ACTION,
  GET_READINGS_ACTION,
  RESET_READINGS_ACTION,
  ADD_READINGS_ACTION,
  UPDATE_READINGS_ACTION,
  ADD_DISCOUNT_RATES_ACTION,
  UPDATE_DISCOUNT_RATES_ACTION,
  GET_LINE_ITEMS_ACTION,
  ADD_LINE_ITEMS_ACTION,
  UPDATE_LINE_ITEMS_ACTION,
  GET_ACTIVE_LINE_ITEMS_ACTION,
  GET_NON_ACTIVE_LINE_ITEMS_ACTION,
  ADD_ACTIVE_LINE_ITEMS_ACTION,
  DELETE_ACTIVE_LINE_ITEMS_ACTION,
  GENERATE_TEST_INVOICES_ACTION,
  GET_PREVIOUS_INVOICES_ACTION,
  GET_UNPAID_INVOICES_ACTION,
  GET_MISSING_UNIT_READINGS_ACTION,
  GET_UNIT_INVOICE_READY_READINGS_ACTION,
  UPDATE_UNPAID_INVOICES_ACTION,
  GET_UNPAID_ALL_INVOICES_ACTION,
  GET_STATEMENT_ALL_ACTION,
  GET_STATEMENT_UNIT_ACTION
} from "./types";

export function loginUser(user, pass) {
  //console.log("attemping login...");
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user", {
          username: user,
          password: pass,
        })
        .then((response) =>
          dispatch({
            type: POST_LOGIN_ACTION,
            payload: response.data,
          })
        )
        .catch((response) =>
          dispatch({
            type: POST_LOGIN_ACTION,
            error: response.error,
          })
        );
    };
  }
}

export function loginFailure() {
  return (dispatch) => {
    dispatch({
      type: FAILED_LOGIN_ACTION,
      payload: "",
    });
  };
}

export function getTFA(name) {
  //console.log(name);
  return (dispatch) => {
    axios
      .get("/tfa/get", {
        params: {
          name: name,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TFA_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_TFA_ACTION,
          error: response.error,
        })
      );
  };
}

export function postTFA(secret, code, name, username) {
  //console.log(username);
  return (dispatch) => {
    axios
      .post("/tfa/confirm", {
        secret: secret,
        code: code,
        name: name,
        username: username,
      })
      .then((response) =>
        dispatch({
          type: POST_TFA_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: POST_TFA_ACTION,
          error: response.error,
        })
      );
  };
}

export function loginUserTFA(user, pass, code) {
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user/tfa", {
          username: user,
          password: pass,
          code: code,
        })
        .then((response) =>
          dispatch({
            type: POST_TFALOGIN_ACTION,
            payload: response.data,
          })
        )
        .catch((response) =>
          dispatch({
            type: POST_TFALOGIN_ACTION,
            error: response.error,
          })
        );
    };
  }
}

export function resetState() {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_ACTION,
    });
  };
}

export function userTimeout() {
  return (dispatch) => {
    dispatch({
      type: USER_TIMEOUT_ACTION,
      payload: false,
    });
  };
}

export function verifyAuthentication() {
  return (dispatch) => {
    axios
      .get("/checkauth")
      .then((response) =>
        dispatch({
          type: CHECK_LOGGEDIN_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: CHECK_LOGGEDIN_ACTION,
          error: false,
        })
      );
  };
}

export function documentHeight(value) {
  return (dispatch) => {
    dispatch({
      type: GET_DOCUMENT_HEIGHT_ACTION,
      payload: value,
    });
  };
}

export function updatePassword(user, passOld, passNew) {
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user/passwordchange", {
          username: user,
          passwordOld: passOld,
          passwordNew: passNew,
        })
        .then((response) =>
          dispatch({
            type: UPDATE_PASSWORD_ACTION,
            payload: response.data,
          })
        )
        .catch((response) =>
          dispatch({
            type: UPDATE_PASSWORD_ACTION,
            error: "",
          })
        );
    };
  }
}

export function waitingServerResponse(bool) {
  return (dispatch) => {
    dispatch({
      type: WAITING_SERVER_RESPONSE_ACTION,
      payload: bool,
    });
  };
}

export function getBuildingList() {
  return (dispatch) => {
    axios
      .get("/building/get")
      .then((response) =>
        dispatch({
          type: GET_BUILDING_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_BUILDING_ACTION,
          error: [],
        })
      );
  };
}

export function addBuilding(name, street, suburb, postCode, state, nmi) {
  return (dispatch) => {
    axios
      .post("/building/add", {
        name: name,
        street: street,
        suburb: suburb,
        postCode: postCode,
        state: state,
        nmi: nmi,
      })
      .then((response) =>
        dispatch({
          type: ADD_BUILDING_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_BUILDING_ACTION,
          error: false,
        })
      );
  };
}

export function updateBuilding(id, name, street, suburb, postCode, state, nmi) {
  return (dispatch) => {
    axios
      .post("/building/update", {
        id: id,
        name: name,
        street: street,
        suburb: suburb,
        postCode: postCode,
        state: state,
        nmi: nmi,
      })
      .then((response) =>
        dispatch({
          type: UPDATE_BUILDING_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_BUILDING_ACTION,
          error: false,
        })
      );
  };
}

export function getUnitList(id) {
  return (dispatch) => {
    axios
      .get("/unit/get", {
        params: {
          id: id
        }
      })
      .then((response) =>
        dispatch({
          type: GET_UNIT_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_UNIT_ACTION,
          error: [],
        })
      );
  };
}

export function resetUnitList() {
  return (dispatch) => {
    dispatch({
      type: RESET_UNIT_ACTION,
      payload: [],
    })
  };
}

export function addUnit(id, unitNum, meterId, securityDeposit, dateMoveIn, discountRate) {
  return (dispatch) => {
    axios
      .post("/unit/add", {
        id: id,
        unitNum: unitNum,
        meterId: meterId,
        securityDeposit: securityDeposit,
        dateMoveIn: dateMoveIn,
        discountRate: discountRate,
      })
      .then((response) =>
        dispatch({
          type: ADD_UNIT_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_UNIT_ACTION,
          error: false,
        })
      );
  };
}

export function getDiscountRates() {
  return (dispatch) => {
    axios
      .get("/discount_rates/get")
      .then((response) =>
        dispatch({
          type: GET_DISCOUNT_RATES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_DISCOUNT_RATES_ACTION,
          error: [],
        })
      );
  };
}

export function updateUnit(unitNumOld, unitNumNew, meterId, securityDeposit, dateMoveIn, dateMoveOut, discountRate, id, archived) {
  return (dispatch) => {
    axios
      .post("/unit/update", {
        unitNumOld: unitNumOld,
        unitNumNew: unitNumNew,
        meterId: meterId,
        securityDeposit: securityDeposit,
        dateMoveIn: dateMoveIn,
        dateMoveOut: dateMoveOut,
        discountRate: discountRate,
        id: id,
        archived: archived,
      })
      .then((response) =>
        dispatch({
          type: UPDATE_UNIT_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_UNIT_ACTION,
          error: false,
        })
      );
  };
}

export function getPeopleList(id) {
  return (dispatch) => {
    axios
      .get("/people/get", {
        params: {
          id: id
        }
      })
      .then((response) =>
        dispatch({
          type: GET_PEOPLE_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_PEOPLE_ACTION,
          error: [],
        })
      );
  };
}

export function resetPeopleList() {
  return (dispatch) => {
    dispatch({
      type: RESET_PEOPLE_ACTION,
      payload: [],
    })
  };
}

export function addPeople(id, name, surname, phone, email, isPrimaryContact) {
  return (dispatch) => {
    axios
      .post("/people/add", {
        id: id,
        name: name,
        surname: surname,
        phone: phone,
        email: email,
        isPrimaryContact: isPrimaryContact
      })
      .then((response) =>
        dispatch({
          type: ADD_PEOPLE_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_PEOPLE_ACTION,
          error: false,
        })
      );
  };
}

export function updatePeople(id, name, surname, phone, email, isPrimaryContact) {
  return (dispatch) => {
    axios
      .post("/people/update", {
        id: id,
        name: name,
        surname: surname,
        phone: phone,
        email: email,
        isPrimaryContact: isPrimaryContact
      })
      .then((response) =>
        dispatch({
          type: UPDATE_PEOPLE_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_PEOPLE_ACTION,
          error: false,
        })
      );
  };
}

export function getReadingsList(id) {
  return (dispatch) => {
    axios
      .get("/readings/get", {
        params: {
          id: id
        }
      })
      .then((response) =>
        dispatch({
          type: GET_READINGS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_READINGS_ACTION,
          error: [],
        })
      );
  };
}

export function resetReadingsList() {
  return (dispatch) => {
    dispatch({
      type: RESET_READINGS_ACTION,
      payload: [],
    })
  };
}

export function addReadings(id, readingStart, readingEnd, dateOfReadingStart, dateOfReadingEnd) {
  return (dispatch) => {
    axios
      .post("/readings/add", {
        id: id,
        readingStart: readingStart,
        readingEnd: readingEnd,
        dateOfReadingStart: dateOfReadingStart,
        dateOfReadingEnd: dateOfReadingEnd
      })
      .then((response) =>
        dispatch({
          type: ADD_READINGS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_READINGS_ACTION,
          error: false,
        })
      );
  };
}

export function updateReadings(id, readingStart, readingEnd, dateOfReadingStart, dateOfReadingEnd) {
  return (dispatch) => {
    axios
      .post("/readings/update", {
        id: id,
        readingStart: readingStart,
        readingEnd: readingEnd,
        dateOfReadingStart: dateOfReadingStart,
        dateOfReadingEnd: dateOfReadingEnd
      })
      .then((response) =>
        dispatch({
          type: UPDATE_READINGS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_READINGS_ACTION,
          error: false,
        })
      );
  };
}

export function addDiscountRate(name, rate) {
  return (dispatch) => {
    axios
      .post("/discount_rates/add", {
        name: name,
        rate: rate
      })
      .then((response) =>
        dispatch({
          type: ADD_DISCOUNT_RATES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_DISCOUNT_RATES_ACTION,
          error: false,
        })
      );
  };
}

export function updateDiscountRate(id, name, rate, senior_rate = 0) {
  return (dispatch) => {
    axios
      .post("/discount_rates/update", {
        id: id,
        name: name,
        rate: rate,
        senior_rate: senior_rate
      })
      .then((response) =>
        dispatch({
          type: UPDATE_DISCOUNT_RATES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_DISCOUNT_RATES_ACTION,
          error: false,
        })
      );
  };
}

export function getLineItems() {
  return (dispatch) => {
    axios
      .get("/line_items/get")
      .then((response) =>
        dispatch({
          type: GET_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_LINE_ITEMS_ACTION,
          error: [],
        })
      );
  };
}

export function addLineItem(type, rate, description, discounted) {
  return (dispatch) => {
    axios
      .post("/line_items/add", {
        type: type,
        rate: rate,
        description: description,
        discounted: discounted
      })
      .then((response) =>
        dispatch({
          type: ADD_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_LINE_ITEMS_ACTION,
          error: false,
        })
      );
  };
}

export function updateLineItem(id, type, rate, description, discounted) {
  return (dispatch) => {
    axios
      .post("/line_items/update", {
        id: id,
        type: type,
        rate: rate,
        description: description,
        discounted: discounted
      })
      .then((response) =>
        dispatch({
          type: UPDATE_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_LINE_ITEMS_ACTION,
          error: false,
        })
      );
  };
}

export function getActiveLineItems(unitid) {
  return (dispatch) => {
    axios
      .get("/active_line_items/get", {
        params: {
          unitid: unitid
        }
      })
      .then((response) =>
        dispatch({
          type: GET_ACTIVE_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_ACTIVE_LINE_ITEMS_ACTION,
          error: [],
        })
      );
  };
}

export function getNonActiveLineItems(unitid) {
  return (dispatch) => {
    axios
      .get("/non_active_line_items/get", {
        params: {
          unitid: unitid
        }
      })
      .then((response) =>
        dispatch({
          type: GET_NON_ACTIVE_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_NON_ACTIVE_LINE_ITEMS_ACTION,
          error: [],
        })
      );
  };
}

export function addActiveLineItem(unitId, itemId) {
  return (dispatch) => {
    axios
      .post("/active_line_items/add", {
        unitId: unitId,
        itemId: itemId,
      })
      .then((response) =>
        dispatch({
          type: ADD_ACTIVE_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: ADD_ACTIVE_LINE_ITEMS_ACTION,
          error: false,
        })
      );
  };
}

export function deleteActiveLineItem(id, unitId, itemId) {
  return (dispatch) => {
    axios
      .post("/active_line_items/delete", {
        id: id,
        unitId: unitId,
        itemId: itemId,
      })
      .then((response) =>
        dispatch({
          type: DELETE_ACTIVE_LINE_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: DELETE_ACTIVE_LINE_ITEMS_ACTION,
          error: false,
        })
      );
  };
}

export function generateTestInvoices(buildingId, dueDate, buildingName) {
  return (dispatch) => {
    axios
      .get("/invoices/test/get", {
        params: {
          buildingId: buildingId,
          dueDate: dueDate,
          buildingName: buildingName,
        }, responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const [, filename] = response.headers['content-disposition'].split('filename=');
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        dispatch({
          type: GENERATE_TEST_INVOICES_ACTION,
          payload: response.data,
        })
      }
      )
      .catch((response) =>
        dispatch({
          type: GENERATE_TEST_INVOICES_ACTION,
          error: false,
        })
      );
  };
}

export function retrievePastInvoices(id) {
  return (dispatch) => {
    axios
      .get("/invoices/previous/get", {
        params: {
          id: id,
          invoiceType: 1,
        }
      })
      .then((response) =>
        dispatch({
          type: GET_PREVIOUS_INVOICES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_PREVIOUS_INVOICES_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveUnpaidInvoices(id) {
  return (dispatch) => {
    axios
      .get("/invoices/unpaid/get", {
        params: {
          id: id,
          invoiceType: 0,
        }
      })
      .then((response) =>
        dispatch({
          type: GET_UNPAID_INVOICES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_UNPAID_INVOICES_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveMissingUnitReadings() {
  return (dispatch) => {
    axios
      .get("/unit/no_reading/get")
      .then((response) =>
        dispatch({
          type: GET_MISSING_UNIT_READINGS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_MISSING_UNIT_READINGS_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveReadyInvoiceUnitReadings() {
  return (dispatch) => {
    axios
      .get("/unit/invoice_ready/get")
      .then((response) =>
        dispatch({
          type: GET_UNIT_INVOICE_READY_READINGS_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_UNIT_INVOICE_READY_READINGS_ACTION,
          error: [],
        })
      );
  };
}

export function updateUnpaidInvoices(id, amount, isFinal, date) {
  return (dispatch) => {
    axios
      .post("/invoices/unpaid/update", {
        id: id,
        amount: amount,
        isFinal: isFinal,
        date
      })
      .then((response) =>
        dispatch({
          type: UPDATE_UNPAID_INVOICES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: UPDATE_UNPAID_INVOICES_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveUnpaidAllInvoices() {
  return (dispatch) => {
    axios
      .get("/invoices/unpaid/get/all")
      .then((response) =>
        dispatch({
          type: GET_UNPAID_ALL_INVOICES_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_UNPAID_ALL_INVOICES_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveStatementAll() {
  return (dispatch) => {
    axios
      .get("/statement/get")
      .then((response) =>
        dispatch({
          type: GET_STATEMENT_ALL_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_STATEMENT_ALL_ACTION,
          error: [],
        })
      );
  };
}

export function retrieveStatementUnit(unitid) {
  return (dispatch) => {
    axios
      .get("/statement/unit/get", {
        params: {
          unitid: unitid
        }
      })
      .then((response) =>
        dispatch({
          type: GET_STATEMENT_UNIT_ACTION,
          payload: response.data,
        })
      )
      .catch((response) =>
        dispatch({
          type: GET_STATEMENT_UNIT_ACTION,
          error: [],
        })
      );
  };
}