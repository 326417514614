/*
Authors: Joel Wilkinson
Details: App.js is the main component of UB
which handles the routing of UB.

Country Consulting ©2021
*/

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./Components/Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";

import "./App.css";
import "typeface-roboto";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Home} />
            <Route path="/dashboard/" component={Dashboard} />
            <Redirect from="*" to="/login" />
          </Switch>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
