/*
Authors: Joel Wilkinson
Details: NavBar.js is the component that renders the navbar
to the user. Depending on if the user is viewing via desktop,
tablet, or mobile, the render will look different.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";

import Password from "../SideBar/Password";
import TwoFactorAuth from "../SideBar/TwoFactorAuth";
import "bootstrap/dist/css/bootstrap.min.css";

import "./NavBar.css";

class NavBar extends React.Component {
  render() {
    return (
      <div className="info">
        <div className="stickyCover"></div>
        <div className="navBarLogo"><TwoFactorAuth /> <Password /></div>
        <div className="user">Hello, {this.props.name}!</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.loginReducer.username,
    name: state.loginReducer.name,
    secret: state.loginReducer.secret,
  };
};

export default connect(mapStateToProps, null)(NavBar);
