/*
Authors: Joel Wilkinson
Details: LineItemTable.js renders the table which handles
the line items a unit can be associated with.

Country Consulting ©2021
*/

import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { getLineItems, addLineItem, updateLineItem } from "../../Actions/functions";

import "./LineItemTable.css";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const LineItemTable = (props) => {

    const [columnData] = useState([
        { title: "Type", field: "type" },
        {
            title: "Rate", field: "rate", initialEditValue: ""
        },
        {
            title: "Description", field: "description"
        },
        {
            title: "Discounted", field: "discounted", lookup: { 1: "YES", 0: "NO" }
        },
    ])

    const { getLineItems } = props;

    useEffect(() => {
        getLineItems();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="line-item-table-container">
            <MaterialTable
                icons={tableIcons}
                title="Line Items"
                columns={columnData}
                data={props.lineItemsList}
                options={{
                    search: true,
                    pageSize: 8,
                    rowStyle: (rowData) => ({
                        backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
                    }),
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const type = newData.type === null || newData.type === undefined ? " " : newData.type;
                                props.addLineItem(type, newData.rate, newData.description, newData.discounted);
                                props.getLineItems();
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const type = newData.type === null || newData.type === undefined ? " " : newData.type;
                                props.updateLineItem(newData.iditem, type, newData.rate, newData.description, newData.discounted);
                                setTimeout(() => {
                                    props.getLineItems();
                                }, 2000);
                                resolve();
                            }, 1000)
                        }),
                }}
            />
        </div>
    );


}

const mapStateToProps = (state) => {
    return {
        lineItemsList: state.lineItemsGetReducer.lineItemsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getLineItems: getLineItems,
            addLineItem: addLineItem,
            updateLineItem: updateLineItem
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LineItemTable);
