import { UPDATE_LINE_ITEMS_ACTION } from "../Actions/types";

const lineItemsUpdateState = {
    lineItemsUpdated: "",
};

const lineItemsUpdateReducer = (state = lineItemsUpdateState, action) => {
    switch (action.type) {
        case UPDATE_LINE_ITEMS_ACTION:
            state = {
                ...state,
                lineItemsUpdated: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default lineItemsUpdateReducer;
