import { GENERATE_TEST_INVOICES_ACTION } from "../Actions/types";

const invoicesTestGenerateState = {
    invoices: "",
};

const invoicesTestGenerateGetReducer = (state = invoicesTestGenerateState, action) => {
    switch (action.type) {
        case GENERATE_TEST_INVOICES_ACTION:
            state = {
                ...state,
                invoices: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default invoicesTestGenerateGetReducer;
