import { CHECK_LOGGEDIN_ACTION } from "../Actions/types";

const authState = {
  sessionExists: false as boolean
};

const checkAuthReducer = (state = authState, action: any) => {
  switch (action.type) {
    case CHECK_LOGGEDIN_ACTION:
      state = {
        ...state,
        sessionExists: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default checkAuthReducer;
