import { ADD_UNIT_ACTION } from "../Actions/types";

const unitAddState = {
    unitAdded: "",
};

const unitAddReducer = (state = unitAddState, action) => {
    switch (action.type) {
        case ADD_UNIT_ACTION:
            state = {
                ...state,
                unitAdded: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default unitAddReducer;
