import { GET_PREVIOUS_INVOICES_ACTION } from "../Actions/types";

const invoicesPreviousGetState = {
    prevInvoices: [],
};

const invoicesPreviousGetReducer = (state = invoicesPreviousGetState, action) => {
    switch (action.type) {
        case GET_PREVIOUS_INVOICES_ACTION:
            state = {
                ...state,
                prevInvoices: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default invoicesPreviousGetReducer;
