import { GET_DISCOUNT_RATES_ACTION } from "../Actions/types";

const discountRatesGetState = {
    discountRatesList: [],
    discountRatesObj: {},
    discountRatesSenior: 0,
    discountRatesListAll: [],
};

const discountRatesGetReducer = (state = discountRatesGetState, action) => {
    switch (action.type) {
        case GET_DISCOUNT_RATES_ACTION:
            const rates = action.payload.filter(rate => rate.senior === 0);

            const obj = action.payload.reduce(function (acc, cur, i) {
                acc[cur.iddiscount_rate] = cur.senior === 1 ? `${cur.name} ($${cur.senior_rate})` : `${cur.name} (${cur.rate}%)`;
                return acc;
            }, {});

            const seniorRate = action.payload.find(e => e.senior === 1);

            // console.log(seniorRate)
            state = {
                ...state,
                discountRatesList: rates,
                discountRatesObj: obj,
                discountRatesSenior: seniorRate,
                discountRatesListAll: action.payload
            };
            return state;
        default:
            return state;
    }
};
export default discountRatesGetReducer;
