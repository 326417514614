/*
Authors: Joel Wilkinson
Details: FormUpdateUnit.js is the component that renders the add
building form.

Country Consulting ©2021
*/

import React, { useState, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";

import { getUnitList, updateUnit } from "../../Actions/functions";

import "./Unit.css";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const FormUpdateUnit = (props) => {

    const [columns,] = useState([
        {
            title: "Unit",
            field: "unitNum",
            type: 'numeric',
            editable: 'never'
        },
        {
            title: "Meter ID",
            field: "meterId",
        },
        {
            title: "Security Deposit",
            field: "securityDeposit",
            type: 'numeric'
        },
        {
            title: "Move In Date",
            field: "dateMoveIn",
            type: 'date',
            render: rowData => "" + format(parseISO(new Date(rowData.dateMoveIn).toISOString()), "dd.MM.yyyy") + "",
        },
        {
            title: "Move Out Date",
            field: "dateMoveOut",
            type: 'date',
            render: rowData => rowData.dateMoveOut === null ? null : "" + format(parseISO(new Date(rowData.dateMoveOut).toISOString()), "dd.MM.yyyy") + "",
        },
        {
            title: "Discount Rate",
            field: "iddiscount_rate",
            lookup: props.discountRatesObj
        },
        {
            title: "Archived",
            field: "archived",
            lookup: { 1: "YES", 0: "NO" }
        }
    ]);

    if (props.unitList !== null && props.unitList !== undefined) {
        return (
            <div style={{ width: "100%" }}>
                <div>
                    <button className="form-btn" onClick={() => props.props.reset(false)}>Close</button>
                </div>
                <div className="form-update-building">
                    <MaterialTable
                        icons={tableIcons}
                        title="Update Building Info"
                        columns={columns}
                        data={props.unitList}
                        options={{
                            showTitle: false,
                            search: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 25, 50, 100],
                            fontSize: 14,
                            rowStyle: (rowData) => ({
                                backgroundColor: rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
                            }),
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    const dateMoveIn = format(parseISO(new Date(newData.dateMoveIn).toISOString()), "yyyy-MM-dd");
                                    const dateMoveOut = newData.dateMoveOut === null ? null : format(parseISO(new Date(newData.dateMoveOut).toISOString()), "yyyy-MM-dd");
                                    // console.log(dateMoveOut);
                                    setTimeout(() => {
                                        props.updateUnit(oldData.unitNum, newData.unitNum, newData.meterId, newData.securityDeposit, dateMoveIn, dateMoveOut, newData.iddiscount_rate, newData.idunit, newData.archived);
                                        props.getUnitList(props.props.buildingId);

                                        resolve();
                                    }, 1000)
                                }),
                        }}
                    />
                </div>
            </div >
        );
    } else {
        return (
            <div>Loading Update Buildings Table...</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unitList: state.unitGetReducer.unitList,
        discountRatesList: state.discountRatesGetReducer.discountRatesList,
        discountRatesObj: state.discountRatesGetReducer.discountRatesObj
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUnitList: getUnitList,
            updateUnit: updateUnit
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdateUnit);