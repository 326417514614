/*
Authors: Joel Wilkinson
Details: FormAddUnit.js is the component that renders the add
unit form.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { getDiscountRates, addUnit, getUnitList } from "../../Actions/functions";

import "./Unit.css";

const FormAddUnit = (props) => {

    const [unitNum, setUnitNum] = useState("");
    const [meterId, setMeterId] = useState("");
    const [securityDeposit, setSecurityDeposit] = useState("");
    const [dateMoveIn, setDateMoveIn] = useState("");
    const [discountRate, setDiscountRate] = useState(1);

    const { getDiscountRates } = props;

    useEffect(() => {
        getDiscountRates();
        // eslint-disable-next-line
    }, []);

    const renderDiscountRates = () => {
        return props.discountRatesListAll.map((k, i) => {
            return <option key={k.iddiscount_rate} value={k.iddiscount_rate}>{k.name}: {k.senior === 0 ? `(${k.rate}%)` : `($${k.senior_rate})`}</option>;
        });
    };

    const handleSubmitAddUnit = (e) => {
        e.preventDefault();

        if (unitNum === "" || meterId === "" || securityDeposit === "" || dateMoveIn === "" || discountRate === "") {
            const fields = [unitNum, meterId, securityDeposit, dateMoveIn, discountRate];
            const fieldNames = [
                "Unit Number", "Meter ID", "Security Deposit", "Move In Date", "Discount Rate"
            ];

            let message = "You have missing fields in:";

            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === "") {
                    message += "\n" + fieldNames[i];
                }
            }

            alert(message);
        } else {
            props.addUnit(props.props.buildingId, unitNum, meterId, securityDeposit, dateMoveIn, discountRate);
            setUnitNum("");
            setMeterId("");
            setSecurityDeposit("");
            setDateMoveIn("");
            setDiscountRate(1);
            setTimeout(() => {
                props.getUnitList(props.props.buildingId);
            }, 2000);
            props.props.reset(false);
        }
    }

    return (
        <div>
            <div>
                <button className="form-btn" onClick={() => props.props.reset(false)}>Close</button>
            </div>
            <Form name="formAddUnit" className="form-add-unit" onSubmit={handleSubmitAddUnit}>
                <Form.Row>
                    <Form.Group as={Col} className="unit-form-group">
                        <Form.Label className="unit-label-field">Unit Number</Form.Label>
                        <Form.Control
                            className="unit-form-control"
                            type="number"
                            placeholder="Unit Number..."
                            name="unitNum"
                            value={unitNum}
                            onChange={e => setUnitNum(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="unit-form-group">
                        <Form.Label className="unit-label-field">Meter ID</Form.Label>
                        <Form.Control
                            className="unit-form-control"
                            type="meterId"
                            placeholder="Meter ID..."
                            name="meterId"
                            value={meterId}
                            onChange={e => setMeterId(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="unit-form-group">
                        <Form.Label className="unit-label-field">Security Deposit</Form.Label>
                        <Form.Control
                            className="unit-form-control"
                            type="number"
                            placeholder="Security Deposit..."
                            name="securityDeposit"
                            value={securityDeposit}
                            onChange={e => setSecurityDeposit(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="unit-form-group">
                        <Form.Label className="unit-label-field">Move In Date</Form.Label>
                        <Form.Control
                            className="unit-form-control"
                            type="date"
                            placeholder="Move In Date..."
                            name="dateMoveIn"
                            value={dateMoveIn}
                            onChange={e => setDateMoveIn(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="unit-form-group">
                        <Form.Label className="unit-label-field">Discount Rate</Form.Label>
                        <Form.Control
                            as="select"
                            className="unit-form-control"
                            type="discountRate"
                            placeholder="Discount Rate..."
                            name="discountRate"
                            value={discountRate}
                            onChange={e => setDiscountRate(e.target.value)}
                        >
                            {renderDiscountRates()}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Group as={Col}>
                    <button type="submit" className="form-btn">Add unit</button>
                </Form.Group>
            </Form>
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        discountRatesListAll: state.discountRatesGetReducer.discountRatesListAll
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDiscountRates: getDiscountRates,
            addUnit: addUnit,
            getUnitList: getUnitList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddUnit);
