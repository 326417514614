/*
Authors: Joel Wilkinson
Details: LineItem.js is the component for rendering lineitem data.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getActiveLineItems, getNonActiveLineItems } from "../../Actions/functions";

import "./LineItem.css";

const LineItem = (props) => {
    const handleUpdate = () => {
        if (props.props.id !== null) {
            props.props.handleLineItem(props.props.id, props.props.unitId, props.props.itemId);
        } else {
            props.props.handleLineItem(props.props.unitId, props.props.itemId);
        }
        setTimeout(() => {
            props.getActiveLineItems(props.props.unitId);
            props.getNonActiveLineItems(props.props.unitId);
        }, 100);
    }
    return (
        <div className="line-item-container" onClick={() => handleUpdate()}>
            <p className="line-item-type">{props.props.type}</p>
            <p className="line-item-rate">{props.props.rate}</p>
            <p className="line-item-description">{props.props.description}</p>
        </div>
    );


}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getActiveLineItems: getActiveLineItems,
            getNonActiveLineItems: getNonActiveLineItems
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LineItem);
